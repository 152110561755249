import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import { Spin } from 'antd';

import { Button, Typography } from '../../../../../1_shared/ui';
import { IPromoCodeOutput } from '../../../model/api/interfaces/IPromoCodeOutput';
import { IApplicationFormAgreement } from '../../interface/IApplicationForm';

import styles from '../../OrderForm.module.scss';

const PromoCodeSuffixButton = ({
  field,
  slotId,
  promoCode,
  user,
  onPromoCodeFind,
  isPromoLoading,
  setPromoCode,
}: {
  field: ControllerRenderProps<IApplicationFormAgreement, 'promoCode'>;
  slotId: string;
  user: any;
  onPromoCodeFind: any;
  isPromoLoading: boolean;
  setPromoCode: Dispatch<SetStateAction<any>>;
  promoCode?: IPromoCodeOutput;
}): ReactElement => {
  const { setValue } = useFormContext<IApplicationFormAgreement>();
  return !promoCode ? (
    <Button
      type="text"
      disabled={!field.value || !slotId || !user}
      onClick={() => {
        onPromoCodeFind({
          name: String(field.value),
          slotId,
          userId: user?.userId as string,
        });
        setValue('isPromoCodeSubmit', true);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        {user && (!isPromoLoading ? `ПРИМЕНИТЬ` : <Spin />)}
      </Typography>
    </Button>
  ) : (
    <Button
      type="text"
      disabled={!field.value || !slotId || !user}
      onClick={() => {
        setPromoCode(undefined);
        field.onChange('');
        setValue('isPromoCodeSubmit', false);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        ИЗМЕНИТЬ
      </Typography>
    </Button>
  );
};

export default PromoCodeSuffixButton;
