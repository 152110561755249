import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Spin } from 'antd';

import { DurationType } from '../../../../1_shared/config/interfaces/DurationType';
import { ISpecialistByIdData } from '../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { timeConfig } from '../../../../1_shared/config/timeConfig';
import { Typography } from '../../../../1_shared/ui';
import { SpecialistAvatar } from '../../../../2_entities';
import { SlotSelect } from '../../../../3_features';

import styles from '../OrderForm.module.scss';

/**
 * Поле для отображения специалиста к которому оформляется заявка на сессию и выбор доступного
 * */
const OrderFormSpecialistField = ({
  isLoading,
  spec,
  avatar,
  setPromoCode,
}: {
  isLoading: boolean;
  avatar: string;
  setPromoCode: Dispatch<SetStateAction<any>>;
  spec?: ISpecialistByIdData;
}): ReactElement => {
  const { control } = useFormContext();
  return (
    <>
      <div className={styles.row}>
        <Typography type="subtitle" className={styles.rowText}>
          СПЕЦИАЛИСТ
        </Typography>
        {isLoading ? (
          <Spin />
        ) : (
          <SpecialistAvatar
            firstName={String(spec?.firstName)}
            lastName={String(spec?.secondName)}
            imgPreview={avatar}
          />
        )}
      </div>
      <Controller
        control={control}
        name="slotId"
        render={({ field }) => {
          const duration = spec?.slots.find(
            el => el.slotId === field.value,
          )?.duration;
          return (
            <div className={styles.row}>
              <Typography type="subtitle" className={styles.rowText}>
                ДАТА И ВРЕМЯ
              </Typography>

              <SlotSelect
                slots={spec?.slots ?? []}
                {...field}
                onChange={elem => {
                  field.onChange(elem);
                  setPromoCode(undefined);
                }}
              />
              {duration && (
                <Typography type="description">
                  {`Длительность сеанса ${timeConfig[duration as DurationType]}`}
                </Typography>
              )}
            </div>
          );
        }}
      />
    </>
  );
};

export default OrderFormSpecialistField;
