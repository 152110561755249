import Promo1Img from '1_shared/assets/images/promoCampaigns/NewYear2024/icons/firstBadge.svg';
import Promo2Img from '1_shared/assets/images/promoCampaigns/NewYear2024/icons/secondBadge.svg';
import Promo3Img from '1_shared/assets/images/promoCampaigns/NewYear2024/icons/thirdBadge.svg';

/**
 * Enum для проведения промо-акции
 * @readonly
 * @enum {ACQUAINTANCE, ACQUAINTANCE_AND_DIVE, ACQUAINTANCE_DIVE_AND_GROWTH} для акции Новый Год 2024
 * */
export enum EItemCampaignIdentificators {
  ACQUAINTANCE = 'ACQUAINTANCE',
  ACQUAINTANCE_AND_DIVE = 'ACQUAINTANCE_AND_DIVE',
  ACQUAINTANCE_DIVE_AND_GROWTH = 'ACQUAINTANCE_DIVE_AND_GROWTH',
  STANDART = 'STANDART',
}

export interface IPromoCampaignItems {
  itemTitle: { title: string; description?: string };
  itemBenefits: { benefit: string; discount: string }[];
  itemPhoto: any;
  itemCampaignIdentificator: EItemCampaignIdentificators;
}

export const NewYear2024: IPromoCampaignItems[] = [
  {
    itemTitle: {
      title: `Знакомство: `,
      description: 'найди свой метод и своего специалиста',
    },
    itemBenefits: [
      {
        benefit: '3 сессии с разными специалистами на выбор со скидкой',
        discount: '20%',
      },
    ],
    itemPhoto: Promo1Img,
    itemCampaignIdentificator: EItemCampaignIdentificators.ACQUAINTANCE,
  },
  {
    itemTitle: {
      title: `Знакомство и погружение: `,
      description: 'начни работу со своим специалистом',
    },
    itemBenefits: [
      {
        benefit: '3 сессии с разными специалистами на выбор со скидкой',
        discount: '20%',
      },
      {
        benefit: '5 сессий с конкретным выбранным специалистом со скидкой',
        discount: '5%',
      },
    ],
    itemPhoto: Promo2Img,
    itemCampaignIdentificator:
      EItemCampaignIdentificators.ACQUAINTANCE_AND_DIVE,
  },
  {
    itemTitle: { title: `Знакомство, погружение и развитие` },
    itemBenefits: [
      {
        benefit: '3 сессии с разными специалистами на выбор со скидкой',
        discount: '20%',
      },
      {
        benefit: '10 сессий с конкретным выбранным специалистом со скидкой',
        discount: '10%',
      },
    ],
    itemPhoto: Promo3Img,
    itemCampaignIdentificator:
      EItemCampaignIdentificators.ACQUAINTANCE_DIVE_AND_GROWTH,
  },
];
