import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import pdf_offerAgreement from '../../../../1_shared/assets/docs/Договор_клиентский_оферты_ООО_ПКО.pdf';
import pdf_dataProcessing
  from '../../../../1_shared/assets/docs/Договор_Обработки_персональных_данных_специалист_DoTherapy.pdf';
import pdf_userAgreement from '../../../../1_shared/assets/docs/Пользовательское_соглашение_DoTherapy.pdf';
import { CheckboxCircle, Typography } from '../../../../1_shared/ui';

import styles from '../OrderForm.module.scss';
import { IApplicationFormAgreement } from '../interface/IApplicationForm';

/**
 * Блок с соглашениями об обработке информации на странице заявки на сессию
 * */
const OrderFormAgreements = (): ReactElement => {
  const { control } = useFormContext<IApplicationFormAgreement>();

  return (
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        СОГЛАСИЯ
      </Typography>
      <div className={styles.checkboxWrapper}>
        <Controller
          control={control}
          name="agreementTerms"
          render={({ field: { onChange, value } }) => (
            <CheckboxCircle
              checked={!!value}
              value={!!value}
              onChange={onChange}
            >
              Принять{' '}
              <a
                href={pdf_userAgreement}
                target="_blank"
                data-title="A new page"
                rel="noopener noreferrer"
                className={styles.link}
              >
                пользовательское соглашение
              </a>{' '}
              и{' '}
              <a
                href={pdf_offerAgreement}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                договор оферты
              </a>
            </CheckboxCircle>
          )}
        />
        <Controller
          control={control}
          name="agreementDataProcessing"
          render={({ field: { onChange, value } }) => (
            <CheckboxCircle
              checked={!!value}
              value={!!value}
              onChange={onChange}
            >
              Согласие на{' '}
              <a
                href={pdf_dataProcessing}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                обработку данных
              </a>
            </CheckboxCircle>
          )}
        />
      </div>
    </div>
  );
};

export default OrderFormAgreements;
