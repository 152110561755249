import { ReactElement, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ELoginStatus } from '../../../../../1_shared/config/enums/ELoginStatus';
import { ISlot } from '../../../../../1_shared/config/interfaces/ISlot';
import { ISpecialistByIdData } from '../../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { ISpecialistData } from '../../../../../1_shared/config/interfaces/ISpecialistData';
import { ISpecialistShortData } from '../../../../../1_shared/config/interfaces/ISpecialistShortData';
import { Typography } from '../../../../../1_shared/ui';
import { EnterCode } from '../../../../../3_features';
import { IClientPreviewOutput } from '../../../../../app/api/interfaces/IClientPreviewOutput';
import { configMedia } from '../../../../SpecialistCard/ui/config/configMedia';
import usePayment from '../../../model/usePayment';
import { IApplicationForm, IApplicationFormAgreement } from '../../interface/IApplicationForm';
import OrderFormAgreements from '../../OrderFormComponents/OrderFormAgreements';
import OrderFormInformationRow from '../../OrderFormComponents/OrderFormInformationRow';
import OrderFormLoginField from '../../OrderFormComponents/OrderFormLoginField';
import OrderFormNameField from '../../OrderFormComponents/OrderFormNameField';
import OrderFormPromoCodeField from '../../OrderFormComponents/OrderFormPromoCodeField/OrderFormPromoCodeField';
import OrderFormSpecialistField from '../../OrderFormComponents/OrderFormSpecialistField';

import styles from '../../OrderForm.module.scss';

/**
 * Компонент формы для оформления клиентом новой заявки или оплаты заявки если она была оформлена ранее
 * */
const OrderFormForClients = ({
  user,
  status,
  slotId,
  isLoading,
  updateNickname,
  loginFieldProps,
  disable, // TODO: [DOT-362] дизейблить все поля кроме промокода
  spec,
}: {
  user: IClientPreviewOutput | ISpecialistData | null;
  status: any;
  slotId: string;
  isLoading: boolean;
  updateNickname: any;
  loginFieldProps: any;
  disable: boolean;
  spec?: ISpecialistByIdData;
}): ReactElement => {
  const { setStatus, openCode, isLogin, foundUser, registerUser, setOpenCode } =
    loginFieldProps;

  const { handleSubmit, watch, getValues, setValue } =
    useFormContext<IApplicationFormAgreement>();
  watch([
    'slotId',
    'login',
    'name',
    'promoCode',
    'code',
    'agreementTerms',
    'agreementDataProcessing',
  ]);

  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    payment,
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setPromoCode,
  } = usePayment(updateNickname);

  const slot = useMemo(
    () =>
      spec?.slots?.find(slot =>
        slotId
          ? slot.slotId === slotId
          : slot.slotId === (state as any)?.slot?.slotId ||
            spec?.slots[0]?.slotId,
      ),
    [slotId, spec, state],
  );
  const price = useMemo(
    () =>
      spec?.sessionsInfo.find(info => info.sessionType === slot?.kind)?.price ??
      0,
    [slot],
  );
  const avatar = useMemo(
    () =>
      spec?.mediaContentResponse.find(el => el.isPrimary)?.mediaContentResponse
        .previewLink ??
      spec?.mediaContentResponse[0]?.mediaContentResponse.previewLink ??
      configMedia.mediaContentResponse.previewLink,
    [spec],
  );

  const onSubmit = async (data: IApplicationForm) => {
    // Удаление данных о ранее заполненной анкете из LS
    localStorage.removeItem('userByAnketa');

    await payment(
      Number(promoCode?.basic_price ?? price),
      data,
      spec as ISpecialistShortData,
      slot as ISlot,
    );
  };

  useEffect(() => {
    if (status === ELoginStatus.NotFound && getValues('login')) {
      (async () => await registerUser(getValues('login') || ''))();
    }
  }, [status]);

  useEffect(() => {
    // TODO: [DOT-361] если пользователь не авторизован и disabled=true - перебрасывать его на страницу авторизации и после возвращать обратно на страницу оплаты
    /** Редирект на каталог специалистов если данные не актуальны */
    if (isLoading) return;
    if ((!price || !slot) && !!spec?.latinId) {
      navigate(`/specialists/specialist/${spec?.latinId}`);
    }

    if (!spec?.id) {
      navigate(`/specialists`);
    }
  }, [isLoading]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
        <div onSubmit={handleSubmit(onSubmit)} className={styles.formGrid}>
          <OrderFormSpecialistField
            isLoading={isLoading}
            avatar={avatar}
            spec={spec}
            setPromoCode={setPromoCode}
          />
          {!user?.userId && (
            <OrderFormLoginField
              {...loginFieldProps}
              status={status}
              user={user}
            />
          )}
          {(user || (!isLogin && status === ELoginStatus.CodeSended)) && (
            <OrderFormNameField />
          )}
          <OrderFormPromoCodeField
            {...{
              promoCode,
              user,
              onPromoCodeFind,
              isPromoLoading,
              errorPromo,
              setPromoCode,
            }}
          />
          <OrderFormAgreements />
        </div>
        {price ? (
          <OrderFormInformationRow
            {...{ user, promoCode, slot, status, price }}
          />
        ) : (
          <Typography type="textM" className={styles.error}>
            У специалиста не указана цена для слота.
          </Typography>
        )}
      </form>
      <EnterCode
        name="code"
        {...{ setStatus, openCode, isLogin, foundUser, registerUser }}
        onClose={() => {
          if (setOpenCode) {
            setOpenCode(!openCode);
          }
          setValue('code', undefined);
        }}
      />
    </>
  );
};

export default OrderFormForClients;
