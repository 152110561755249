import { ReactElement, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useSWR from 'swr';

import { getSpecById } from '1_shared/api/specialistById/specService';
import { Breadcrumbs, Typography } from '1_shared/ui';

import { TPromoCode } from '../../../1_shared/config/types/TPromoCode';
import { schema } from '../config/schema';

import { breadCrumbsOrderForm } from './constants/breadCrumbsOrderForm';
import { EOrderFormType } from './constants/EOrderFormType';
import { IApplicationFormAgreement } from './interface/IApplicationForm';
import OrderFormByType from './OrderFormByType/ui/OrderFormByType';

import styles from './OrderForm.module.scss';

const OrderForm = (): ReactElement => {
  const { state } = useLocation();
  const userByAnketa = useRef<{ name: string; phone: string }>(
    JSON.parse(localStorage.getItem('userByAnketa') || '{}'),
  );

  const { data: spec, isLoading } = useSWR(
    `/spec/specialist/${(state as any)?.id}`,
    getSpecById,
  );

  const promoCodes = JSON.parse(localStorage.getItem('promoCodes') || '[]');
  const actualPromoCode: TPromoCode = promoCodes?.find(
    (item: TPromoCode) => item?.name,
  ) ?? { name: '' };

  const methods = useForm<IApplicationFormAgreement>({
    resolver: yupResolver(schema),
    defaultValues: {
      login: userByAnketa.current?.phone ?? '',
      slotId:
        (state as any)?.slot?.slotId ?? spec?.slots[0]?.slotId ?? undefined,
      promoCode: actualPromoCode?.name,
      isPromoCodeSubmit: false,
    },
  });

  return (
    <div className={styles.root}>
      <div>
        <Breadcrumbs items={breadCrumbsOrderForm} />
        <Typography type="h2">Запись на сессию</Typography>
      </div>
      <FormProvider {...methods}>
        <OrderFormByType
          spec={spec}
          isLoading={isLoading}
          userByAnketa={userByAnketa}
          formType={
            (state as any)?.orderFormType ?? EOrderFormType.PAYMENT_FORM
          }
        />
      </FormProvider>
    </div>
  );
};

export default OrderForm;
