import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import ProfileUser from '1_shared/assets/images/UserProfile.svg';
import ProfileUserPast from '1_shared/assets/images/UserProfilePast.svg';
import { Button, CheckboxCircle, Typography } from '1_shared/ui';

import { EReminderType } from '../../../1_shared/config/enums/EReminderType';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { SpecialistAvatar } from '../../../2_entities';
import useGetCalendarEvent from '../../../2_entities/OrderDetailsReact/model/useGetCalendarEvent';
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import ModalCancelSession from '../../ModalCloseSession/ui/ModalCancelSession';
import ModalFeedback from '../../ModalFeedback/ui/ModalFeedback';
import { ProfileSession } from '../../SessionsPc/api/interfaces/ProfileSession';
import { ETypeTable } from '../config/interfaces/ETypeTable';
import useActionsSession from '../module/useActionsSession';

import { IPropsSessionTable } from './interfaces/IPropsSessionTable';

import styles from './SessionTable.module.scss';

const SessionTable = ({
  typeTable = ETypeTable.Future,
  items,
  mutate,
  isAsClient,
}: IPropsSessionTable) => {
  const { user, role } = useAuthContext();
  const getSessionCalendarEvent = useGetCalendarEvent();

  const { reminderSwitchAction, cancelSessionAction } =
    useActionsSession(mutate);
  const [open, setOpen] = useState<{
    openModal: boolean;
    session: ProfileSession | null;
  }>({
    openModal: false,
    session: null,
  });
  const [cancelSessionModal, setCancelSessionModal] = useState<{
    openModal: boolean;
    sessionId: string;
  }>({
    openModal: false,
    sessionId: '',
  });
  const isSpecialist = role === ERoles.Spec;

  const canBeCancel = useCallback((date: string) => {
    const targetDate = dayjs(date);
    const now = dayjs();
    const hoursDifference = targetDate.diff(now, 'hour');
    return (
      hoursDifference >= 24 && typeTable === ETypeTable.Future && !isSpecialist
    );
  }, []);

  const openModal = (session: ProfileSession) => {
    setOpen({ session, openModal: true });
  };

  const onClose = () => {
    setOpen(prev => ({ ...prev, openModal: false }));
  };

  const openCancelSessionModal = (sessionId: string) => {
    setCancelSessionModal({ sessionId, openModal: true });
  };

  const closeCancelSessionModal = () => {
    setCancelSessionModal(prev => ({ ...prev, openModal: false }));
  };

  const clientPreview =
    typeTable === ETypeTable.Future ? ProfileUser : ProfileUserPast;


  return (
    <div className={styles.root}>
      <table className={styles.tableCont}>
        <thead>
          <tr className={styles.columnsTitle}>
            {isAsClient && (
              <th align="left">
                <Typography type="subtitle">СПЕЦИАЛИСТ</Typography>
              </th>
            )}
            {!isAsClient && (
              <th align="left">
                <Typography type="subtitle">КЛИЕНТ</Typography>
              </th>
            )}
            <th align="left">
              <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
            </th>
            {user?.email && !isSpecialist && (
              <th align="left">
                <Typography type="subtitle">EMAIL</Typography>
              </th>
            )}
            {user?.phone && !isSpecialist && (
              <th align="left">
                <Typography type="subtitle">ТЕЛЕФОН</Typography>
              </th>
            )}
            {!isSpecialist && (
              <th align="left">
                <Typography type="subtitle">СТОИМОСТЬ</Typography>
              </th>
            )}
            {!isSpecialist && (
              <th align="left">
                <Typography type="subtitle">ВАШЕ ИМЯ (ПСЕВДОНИМ)</Typography>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortBy(items, 'start')
            .reverse()
            .map(session => (
              <tr key={session.id} className={styles.item}>
                  {isAsClient && (
                <td>
                    <SpecialistAvatar
                      firstName={session.specName}
                      // lastName={
                      //   session?.specialist?.lastName ?? userData?.secondName
                      // }
                      // patronymic={
                      //   session?.specialist?.patronymic ?? userData?.patronymic
                      // }
                      imgPreview={
                        session?.specAvatar ||
                        configMedia.mediaContentResponse.previewLink
                      }
                      specIdentifier={session?.specLatinId}
                    />
                </td>)}
                {!isAsClient && (
                  <td>
                    <SpecialistAvatar
                      firstName={session.clientNickname ?? '-'}
                      imgPreview={clientPreview}
                    />
                  </td>
                )}
                <td>
                  <div className={styles.subItem}>
                    <Typography>
                      {dayjs(session.start, 'YYYY-MM-DDThh:mm:ss').format(
                        'DD MMMM, HH:mm',
                      )}
                    </Typography>
                    {typeTable === ETypeTable.Future && !isSpecialist && (
                      <button
                        className={styles.btnText}
                        type="button"
                        onClick={() => getSessionCalendarEvent(session?.slotId)}
                      >
                        <div className={styles.calendar} />

                        <Typography
                          type="description"
                          className={styles.btnTextDescr}
                        >
                          Добавить в календарь
                        </Typography>
                      </button>
                    )}
                  </div>
                </td>
                {user?.email && !isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography className={styles.longText}>
                        {user.email}
                      </Typography>
                      {typeTable === ETypeTable.Future && (
                        <CheckboxCircle
                          onChange={() =>
                            reminderSwitchAction(
                              session.id,
                              EReminderType.Email,
                            )
                          }
                          checked={
                            !!session.reminders.find(
                              reminder => reminder.type === EReminderType.Email,
                            )
                          }
                        >
                          Оповещать
                        </CheckboxCircle>
                      )}
                    </div>
                  </td>
                )}
                {user?.phone && !isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography>{phoneConvert(user?.phone)}</Typography>
                      {typeTable === ETypeTable.Future && (
                        <CheckboxCircle
                          onChange={() =>
                            reminderSwitchAction(
                              session.id,
                              EReminderType.Phone,
                            )
                          }
                          checked={
                            !!session.reminders?.find(
                              reminder => reminder.type === EReminderType.Phone,
                            )
                          }
                        >
                          Оповещать
                        </CheckboxCircle>
                      )}
                    </div>
                  </td>
                )}
                {!isSpecialist && (
                  <td>
                    <Typography>{`${session?.price || 0} ₽`}</Typography>
                  </td>
                )}
                {!isSpecialist && (
                  <td>
                    <div className={styles.subItem}>
                      <Typography>{user?.nickname}</Typography>
                    </div>
                  </td>
                )}
                <td align="right">
                  {canBeCancel(session.start || '') && (
                    <Button
                      onClick={() => openCancelSessionModal(session.id)}
                      danger
                      className={styles.btnDanger}
                    >
                      ОТМЕНИТЬ СЕАНС
                    </Button>
                  )}
                  {isSpecialist &&
                    typeTable === ETypeTable.Past &&
                    dayjs(items?.[0].start) < dayjs() && (
                      <Button
                        type="secondary"
                        onClick={() => openModal(session)}
                      >
                        ОСТАВИТЬ ОБРАТНУЮ СВЯЗЬ
                      </Button>
                    )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {open.session && (
        <ModalFeedback
          open={open.openModal}
          onClose={onClose}
          session={open.session}
        />
      )}
      {cancelSessionModal.openModal && (
        <ModalCancelSession
          isOpen={cancelSessionModal.openModal}
          closeModal={closeCancelSessionModal}
          onCancel={() => cancelSessionAction(cancelSessionModal.sessionId)}
        />
      )}
    </div>
  );
};

export default SessionTable;
