import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from 'antd/lib';

import { Input, Typography } from '../../../../../1_shared/ui';
import { IApplicationFormAgreement } from '../../interface/IApplicationForm';

import PromoCodeDescription from './PromoCodeDescription';
import PromoCodeSuffixButton from './PromoCodeSuffixButton';

import styles from '../../OrderForm.module.scss';

/**
 * Поле для ввода промокода на странице оформления заявки на сессию
 * */
const OrderFormPromoCodeField = ({
  promoCode,
  user,
  onPromoCodeFind,
  isPromoLoading,
  errorPromo,
  setPromoCode,
}: {
  promoCode: any;
  user: any;
  onPromoCodeFind: any;
  isPromoLoading: any;
  errorPromo: any;
  setPromoCode: Dispatch<SetStateAction<any>>;
}): ReactElement => {
  const { control, getValues } = useFormContext<IApplicationFormAgreement>();

  return (
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        ПРОМОКОД
      </Typography>
      <Controller
        control={control}
        name="promoCode"
        render={({ field }) => (
          <div className={styles.columnBtn}>
            <Tooltip
              placement="topLeft"
              overlayClassName={styles.tooltipContent}
              title={
                !user?.id
                  ? 'Для использования промокода необходимо войти в учетную запись'
                  : ''
              }
            >
              <Input
                {...field}
                className={styles.customInputHeight}
                size="middle"
                disabled={!!promoCode || !user?.id}
                onPressEnter={() =>
                  onPromoCodeFind({
                    name: String(field.value),
                    slotId: getValues('slotId'),
                    userId: user?.userId as string,
                  })
                }
                suffix={
                  <PromoCodeSuffixButton
                    {...{
                      user,
                      field,
                      promoCode,
                      setPromoCode,
                      onPromoCodeFind,
                      isPromoLoading,
                    }}
                    slotId={getValues('slotId')}
                  />
                }
              />
            </Tooltip>
            <Typography type="description" className={styles.error}>
              {errorPromo}
            </Typography>
          </div>
        )}
      />
      <PromoCodeDescription promoCode={promoCode} />
    </div>
  );
};

export default OrderFormPromoCodeField;
