import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import axios from 'axios';
import cn from 'classnames';

import { RoutePath } from '1_shared/config/routes';
import { timeConfig } from '1_shared/config/timeConfig';
import { Button, Typography } from '1_shared/ui';

import useChangeLocalSubscribeStatus from '../../../1_shared/api/hooks/useChangeLocalSubscribeStatus';
import { useSubscriptions } from '../../../1_shared/api/hooks/useNotificationSubcribe';
import { ESlotType } from '../../../1_shared/config/enums/ESlotType';
import { DurationType } from '../../../1_shared/config/interfaces/DurationType';
import { apiLinksByEnv } from '../../../1_shared/constants/apiLinksByEnv';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { isPhoneField } from '../../../4_widgets/LoginForm/lib/checkPhone';
import { ISpecialistReserveSlotProps } from '../types/types';

import styles from './SpecialistReserveSlot.module.scss';

const SpecialistReserveSlot: React.FC<ISpecialistReserveSlotProps> = ({
  spec,
  slot,
  specialistId,
}) => {
  const user = useMemo(
    () => JSON.parse(localStorage.getItem('user') || '{}'),
    [],
  );
  const role = localStorage.getItem('role');

  const [messageApi, contextHolder] = useMessage();
  const navigate = useNavigate();
  const { setUserMessageType, setSpecSubName, setIsSubscribed, setSpecId } =
    useContext(SpecSubContext);
  const { subscribeOnSpec, unsubscribeOnSpec } =
    useChangeLocalSubscribeStatus();
  const { subscribe } = useSubscriptions();
  const { getSubscribes } = useSubscriptions();
  const { changeSubscriptionsInUserObject } = useChangeLocalSubscribeStatus();

  const [isHoverButton, setIsHoverButton] = useState<boolean>(false);

  const duration = slot?.duration
    ? timeConfig[slot?.duration as DurationType]
    : timeConfig[spec.slots[0]?.duration as DurationType] || '50 минут';
  const cost =
    spec?.sessionsInfo?.find(elem => elem?.sessionType === ESlotType.Personal)
      ?.price || 0;
  const specName = `${spec.firstName} ${spec.secondName}`;

  const isSubscribed = user?.id && user?.subscriptions?.includes(spec?.id);
  const subscribeDefaultText = isSubscribed
    ? 'Вы подписаны'
    : 'Оставить заявку';

  const setContext = (
    contactType: EMessageType | null,
    isSubscribed: boolean,
    specId: string,
    specName?: string,
  ) => {
    setUserMessageType(contactType);
    setSpecSubName(specName ?? '');
    setIsSubscribed(isSubscribed);
    setSpecId(specId);
  };

  const handleSubscribe = async () => {
    /** Yandex.Metrics */
    // @ts-ignore
    ym(97788822, 'reachGoal', 'request_slot_btn', {
      isAuthorize: !!user,
      userId: user?.id ?? null,
    });
    /** Проверка авторизации пользователя в системе */
    if (!role) {
      setContext(null, true, spec?.id, specName);
      return navigate('#userNotAuthorized');
    }

    const contactType = isPhoneField(user?.phone || '')
      ? EMessageType.PHONE
      : EMessageType.EMAIL;

    /** Отмена подписки на новые слоты у специалиста */
    if (isSubscribed) {
      const unsubResponse = await axios.delete(
        `${apiLinksByEnv}/ss/api/v2/subscribes?specialist=${spec?.id}`,
        { withCredentials: true },
      );
      if (unsubResponse) {
        setContext(contactType, false, spec?.id, specName);
        unsubscribeOnSpec(spec?.id);
        return navigate('#specSubscription');
      }
      messageApi.error({
        content: 'При попытке отписаться от уведомлений произошла ошибка!',
      });
    }

    /** Подписка на новые слоты у пользователя */
    return await subscribe({
      userContactType: contactType,
      subType: 'SUB_AVAILABLE_SLOTS',
      targetSpecId: spec?.id,
    }).then(() => {
      /** Yandex.Metrics */
      // @ts-ignore
      ym(97788822, 'reachGoal', 'request_slot_send', {
        isAuthorize: !!user,
        userId: user?.id ?? null,
      });
      setContext(contactType, true, spec?.id, specName);
      subscribeOnSpec(spec?.id);
      return navigate('#specSubscription');
    });
  };

  useEffect(() => {
    if (user?.userId)
      (async () => {
        changeSubscriptionsInUserObject(await getSubscribes(user?.userId));
      })();
  }, []);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.reserveInfo}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={styles.description} type="description">
              ЦЕНА СЕССИИ
            </Typography>
            <Typography type="h3" className={styles.titleInfo}>
              {`${cost} ₽`}
            </Typography>
          </div>
          <div className={styles.reserveInfo__item}>
            <Typography className={styles.description} type="description">
              СЕССИЯ
            </Typography>
            <Typography className={styles.titleInfo} type="h3">
              {duration}
            </Typography>
          </div>
        </div>
        {!!spec?.slots.length && (
          <Tooltip
            placement="bottom"
            title={
              !spec?.slots?.length ? 'У специалиста нет доступных слотов' : null
            }
          >
            <Button
              type="primary"
              onClick={() => {
                navigate(RoutePath.ORDER, {
                  state: { id: specialistId, slot: slot || spec.slots[0] },
                });

                // @ts-ignore
                ym(338532526, 'init', {
                  clickmap: true,
                });
              }}
              disabled={!spec?.slots?.length}
              className={styles.orderBtn}
            >
              ЗАПИСАТЬСЯ
            </Button>
          </Tooltip>
        )}
        {(!spec?.slots?.length || isSubscribed) && (
          <Tooltip
            placement="bottom"
            title={
              !isSubscribed
                ? 'У специалиста нет доступных слотов'
                : 'Отписаться от уведомлений о новых слотах'
            }
          >
            <Tag
              className={cn(
                styles.orderBtn,
                styles.subscribeButton,
                styles.subscribeButton,
                {
                  [styles.subscribedButton]: isSubscribed,
                  [styles.unsubscribeButton]: isHoverButton && isSubscribed,
                },
              )}
              color={isHoverButton && isSubscribed ? 'red' : 'white'}
              onMouseEnter={() => setIsHoverButton(true)}
              onMouseLeave={() => setIsHoverButton(false)}
              onClick={handleSubscribe}
            >
              {isHoverButton && isSubscribed
                ? 'Отписаться'
                : subscribeDefaultText}
            </Tag>
          </Tooltip>
        )}
      </div>
      {contextHolder}
    </>
  );
};

export default SpecialistReserveSlot;
