import useSWRMutation from 'swr/mutation';

import { specSubscribe } from '../../../4_widgets/LoginForm/model/api/auth.service';
import { apiLinksByEnv } from '../../constants/apiLinksByEnv';

export const useSubscriptions = () => {
  const { trigger: subscribe } = useSWRMutation(
    'ss/api/v2/subscribes/subscribe',
    specSubscribe,
  );

  const getSubscribes = async (userId: string) =>
    await fetch(
      `${apiLinksByEnv}/ss/api/v2/subscribes/userSubscriptions?userId=${userId}`,
    ).then(res => res.json());

  return { subscribe, getSubscribes };
};
