import { useMemo, useState } from 'react';
import cn from 'classnames';

import { TabContext } from '../module/provider/TabContext';

import { ITabsProps } from './interfaces/ITabsProps';

import styles from './Tabs.module.scss';

const Tabs = ({
  activeTab,
  setActiveTab,
  items,
  defaultActiveTabIndex,
  classNameTabs = '',
  disabledToggle = false,
}: ITabsProps) => {
  const [active, setActive] = useState<string | number>(
    activeTab || defaultActiveTabIndex,
  );
  const onToggleTab = (tabIndex: string | number) => {
    if (!disabledToggle) {
      setActive(tabIndex);
      if (setActiveTab) setActiveTab(tabIndex);
    }
  };

  const activeTabIndex = useMemo(
    () => activeTab ?? active,
    [active, disabledToggle, activeTab],
  );

  const activeContent = useMemo(
    () => items?.find(item => item.key === activeTabIndex),
    [activeTabIndex, items],
  );

  return (
    <section className={styles.root}>
      <TabContext.Provider value={{ setActiveTab: setActive }}>
        <div className={cn(styles.tabs_controls, classNameTabs)}>
          {items?.map(item => (
            <button
              key={item.key}
              className={cn(styles.tabs_control, {
                [styles.tabs_active]: activeTabIndex === item.key,
              })}
              type="button"
              onClick={() => onToggleTab(item.key)}
            >
              {item.label}
            </button>
          ))}
        </div>
        {activeContent?.children}
      </TabContext.Provider>
    </section>
  );
};

export default Tabs;
