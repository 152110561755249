import { useMemo } from 'react';
import useSWR from 'swr';

import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { currentTimeZone } from '../../../1_shared/constants/timeZone';
import { getSessions } from '../api/sessions.service';

import { IInfoSessionsOutput } from './interfaces/IInfoSessionsOutput';

/**
 * Хук на получение списка сессий в ЛК
 * @constructor
 * @param {boolean} isAsClient - отправка запроса для получения данных от роли Клиент
 * @return по умолчанию возвращает объект на основании роли пользователя
 * @return при добавлении параметра - параметр является приоритетным
 */
const useInfoSessions = (isAsClient: boolean): IInfoSessionsOutput => {
  const role = localStorage.getItem('role');

  const { data, isLoading, mutate } = useSWR(
    `/ss/v2/sessions/profile?isAsClient=${isAsClient || role === ERoles.Client}&timezone=${currentTimeZone}`,
    getSessions,
    {
      refreshInterval: 10000,
    },
  );

  const activeSession = useMemo(() => data?.activeSession, [data]);

  const futureSessions = useMemo(() => data?.futureSessions, [data]);

  const pastSessions = useMemo(() => data?.pastSessions, [data]);

  return {
    activeSession,
    futureSessions,
    pastSessions,
    mutate,
    isLoading,
  };
};

export default useInfoSessions;
