import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { groupBy } from 'lodash';

import { ChipsAction, Typography } from '1_shared/ui';

import { getDictionaries } from '../../../1_shared/api/dictionary';
import useSWRWithCache from '../../../1_shared/api/lib/useSWRWithCache';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';

import './AnketaForm.scss';

const AnketaForm = () => {
  const { control } = useFormContext<IAnketaForm>();
  const { data: dictionaryThemes } = useSWRWithCache(
    '/spec/key-themes',
    getDictionaries,
  );

  const [activeAccordions, setActiveAccordions] = useState<
    Record<string, boolean>
  >({});

  const groupedThemas = useMemo(() => {
    const tempGroup = groupBy(dictionaryThemes, theme => theme.categoryName);
    delete tempGroup[''];
    return tempGroup;
  }, [dictionaryThemes]);

  const toggleAccordion = (groupName: string) => {
    setActiveAccordions(prev => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const addToArray = (
    tempValue: string,
    onChange: (value: string[]) => void,
    arrValue?: string[],
  ) => {
    if (arrValue?.length) {
      if (!arrValue.includes(tempValue)) {
        onChange([...arrValue, tempValue]);
      }
    } else {
      onChange([tempValue]);
    }
  };

  const remove = (
    tempValue: string,
    onChange: (value: string[]) => void,
    arrValue?: string[],
  ) => {
    if (arrValue?.length) {
      onChange(arrValue.filter(val => val !== tempValue));
    }
  };

  return (
    <section className="root">
      <Typography className="title" type="title">
        Что вы хотели бы обсудить с психологом
      </Typography>
      <Controller
        control={control}
        name="keyThemas"
        render={({ field: { value: arrKeyThemas, onChange } }) => (
          <ul className="accordion" data-accordion="parent">
            {Object.keys(groupedThemas).map(nameGroup => (
              <li
                key={nameGroup}
                className="accordionItem"
                data-accordion="element"
              >
                <div className="wrapperItem">
                  <a
                    href="/"
                    className="accordionBtn"
                    data-accordion="button"
                    onClick={() => toggleAccordion(nameGroup)}
                  >
                    <Typography>{nameGroup}</Typography>
                  </a>
                  <div
                    data-accordion="content"
                    className={
                      activeAccordions[nameGroup] ? 'activeContent' : ''
                    }
                  >
                    <div className="chips">
                      {groupedThemas[nameGroup].map(value => (
                        <ChipsAction
                          className="contentChips"
                          onClick={() =>
                            addToArray(value?.value, onChange, arrKeyThemas)
                          }
                          onDelete={() =>
                            remove(value?.value, onChange, arrKeyThemas)
                          }
                          active={arrKeyThemas?.includes(value?.value)}
                          key={value?.id}
                        >
                          {value?.label}
                        </ChipsAction>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      />
    </section>
  );
};

export default AnketaForm;
