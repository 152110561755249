import { createContext, Dispatch, SetStateAction } from 'react';

import { EMessageType } from '../constants/EMessageType';

export const SpecSubContext = createContext<{
  userMessageType: EMessageType | null;
  setUserMessageType: Dispatch<SetStateAction<EMessageType | null>>;
  specSubName: string;
  setSpecSubName: Dispatch<SetStateAction<string>>;
  isSubscribed: boolean;
  setIsSubscribed: Dispatch<SetStateAction<boolean>>;
  specId: string;
  setSpecId: (id: string) => void;
}>({
  userMessageType: null,
  specSubName: '',
  isSubscribed: false,
  specId: '',
  setUserMessageType: () => {},
  setSpecSubName: () => {},
  setIsSubscribed: () => {},
  setSpecId: () => {},
});
