import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { z } from 'zod';

import { IUserInfo } from '../../api/interfaces/IUserInfo';
import { ESex } from '../enums/ESex';

export const userInfoSchema = yup.object<IUserInfo>().shape({
  firstName: yup.string().required('Имя обязательно для заполнения'),
  surname: yup.string().required('Фамилия обязательна для заполнения'),
  patronymic: yup.string(),
  sex: yup.string().required('Пол обязателен для заполнения'),
  birthday: yup
    .string()
    .required('Дата рождения обязательна для заполнения')
    .test('is-18-or-older', 'Вам должно быть не менее 18 лет', value => {
      if (!value) return false;
      return dayjs().diff(dayjs(value), 'year') >= 18;
    }),
  email: yup
    .string()
    .matches(
      /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/,
      'Почта не валидная',
    )
    .required('Почта обязательна для заполнения'),
  phone: yup
    .string()
    .matches(
      /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
      'Номер должен быть в виде +7 999 999 99 99',
    )
    .required('Телефон обязателен для заполнения'),
});

export const userEditInfoSchema = z.object({
  avatar: z.custom<RcFile>(),
  media: z.array(z.custom<RcFile>()),
  first_name: z
    .string()
    .min(1, { message: 'Поле "Имя" обязательно для заполнения' }),
  last_name: z
    .string()
    .min(1, { message: 'Поле "Фамилия" обязательна для заполнения' }),
  patronymic: z.string(),
  sex: z
    .union([z.literal(ESex.Male), z.literal(ESex.Female)])
    .refine(val => !!val, { message: 'Поле "Пол" является обязательным' }),
  date_of_birth: z.string().min(1, {
    message: 'Дата рождения обязательна для заполнения',
  }),
  email: z
    .string()
    .email('Некорректный email')
    .min(1, { message: 'Почта обязательна для заполнения' }),
  phone: z
    .string()
    .min(1, { message: 'Телефон обязателен для заполнения' })
    .regex(/([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/i, {
      message: 'Номер должен быть в виде +7 999 999 99 99',
    }),
});

export type TUserEditInfoSchema = z.infer<typeof userEditInfoSchema>;
