import { useNavigate } from 'react-router';
import { Modal, ModalProps } from 'antd';
import useMessage from 'antd/es/message/useMessage';

import { Button, Typography } from '1_shared/ui';

import styles from './AlertModal.module.scss';

const AlertModal = (props: ModalProps) => {
  const [messageApi, contextHolder] = useMessage();
  const navigate = useNavigate();

  return (
    <Modal className={styles.modal} {...props}>
      <div className={styles.root}>
        <Typography type="title">Оставить заявку</Typography>
        <div className={styles.description}>
          Мы сообщим Вам, когда специалист откроет слоты. Для этого необходимо
          войти на платформу или зарегистрироваться.
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.primaryBtn}
            onClick={() => navigate('#login')}
            htmlType="submit"
            type="primary"
          >
            ВОЙТИ
          </Button>
          <Button
            className={styles.infoBtn}
            onClick={() => navigate('#register')}
            htmlType="submit"
            type="secondary"
          >
            РЕГИСТРАЦИЯ
          </Button>
        </div>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default AlertModal;
