import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWRMutation from 'swr/mutation';

import { getPromoCodeForCampaign } from '../../../../4_widgets/LoginForm/model/api/auth.service';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { TPromoCode } from '../../../config/types/TPromoCode';
import {
  EItemCampaignIdentificators,
  IPromoCampaignItems,
} from '../../../constants/promoCampaigns/NewYear2024';
import useGetPromoCode from '../../../helpers/useGetPromoCode';
import { Typography } from '../../index';
import Line from '../assets/underline.svg';

import 'swiper/scss';
import 'app/styles/global/global.scss';
import './threePromoBanners.scss';

const promoTreshHold = 4;

const ThreePromoBanners = ({
  title,
  items,
  isAuth = false,
}: {
  title: string;
  items: IPromoCampaignItems[];
  isAuth?: boolean;
}): ReactElement => {
  const { state }: any = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { addNewPromoCodeInLS } = useGetPromoCode();
  const [messageApi, contextHolder] = useMessage();

  const [promoCode, setPromoCode] = useState<any>({});

  const { trigger } = useSWRMutation(
    '/ls/api/v2/promocodes/addNewPromoCode',
    getPromoCodeForCampaign,
  );

  const getPromoCodeHandler = async (
    promoCodeType: EItemCampaignIdentificators,
  ) => {
    if (!user?.userId || !promoCodeType) {
      return navigate('/login', {
        state: { isNeedRedirect: true, route: '/' },
      });
    }

    setPromoCode((prev: TPromoCode) => ({ ...prev, promoCodeType }));

    const promoCode = await trigger({ userId: user?.userId, promoCodeType });
    setPromoCode((prev: TPromoCode) => ({ ...prev, name: promoCode.name }));

    try {
      addNewPromoCodeInLS(promoCode, promoCodeType);
    } catch (e) {
      console.error('ошибка при поптыке добавить в LS');
    }
  };

  useEffect(() => {
    if (state?.isNeedRedirect) {
      document?.getElementById('promo')?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const promo = JSON.parse(localStorage.getItem('promoCodes') || '[]')?.find(
      (item: TPromoCode) =>
        Object.values(EItemCampaignIdentificators).includes(item.promoCodeType),
    );
    if (promo) {
      setPromoCode((prev: any) => ({ ...prev, ...promo }));
    }
  }, [localStorage.getItem('promoCodes')]);

  return (
    <section className="promoBanners" id="promo">
      <div className="promoBanners__container container">
        <div className="promoBanners__titleBlock">
          <h2 className="promoBanners__title section-title">{title}</h2>
          <img src={Line} className="line" alt="подчеркивание" />
        </div>
        <Typography
          type="description"
          className="promoBanners__descriptionBlock laptop"
        >
          Поддержка в трудные времена — это то, что действительно поможет:
          справиться с тревогой, неуверенностью или сомнениями. Оформите
          выгодный тариф на сессии с психологом в DoTherapy. Заботьтесь о себе и
          близких, начиная Новый год с позитивных изменений!
        </Typography>
        <Typography
          type="description"
          className="promoBanners__descriptionBlock mobile"
        >
          Оформите выгодный тариф на сессии с психологом в DoTherapy. Заботьтесь
          о себе и близких, начиная Новый год с позитивных изменений!
        </Typography>
      </div>
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        centerInsufficientSlides
        breakpoints={{
          1024: {
            spaceBetween: 40,
          },
        }}
        mousewheel={{ enabled: true, releaseOnEdges: true }}
        modules={[Mousewheel]}
        centeredSlidesBounds
        className="promoBanners__slider"
        wrapperClass="promoBanners__items"
        id="promoBannersSlider"
        setWrapperSize
      >
        {items.map(item => (
          <SwiperSlide className="promoBanners__item swiper-slide">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <img src={item.itemPhoto} alt="" />
            </div>
            <div className="promoBanners__itemBlock">
              <Typography type="h3" className="promoBanners__itemTitle">
                {item.itemTitle.title}
              </Typography>
              <Typography type="p" className="promoBanners__itemDescription">
                {item.itemTitle.description}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                justifyContent: 'space-between',
              }}
            >
              <ul className="promoBanners__benefits">
                {item.itemBenefits.map(benefit => (
                  <li className="promoBanners__title-benefit">
                    {benefit.benefit}{' '}
                    <span className="promoBanners__title-discount">
                      {benefit.discount}
                    </span>
                  </li>
                ))}
              </ul>
              {promoCode?.promoCodeType !==
                EItemCampaignIdentificators.STANDART &&
                (promoCode?.promoCodeType !== item.itemCampaignIdentificator ? (
                  <button
                    type="button"
                    className="btn btn--violet promoBanners__selectButton"
                    disabled={
                      promoCode?.usageLeft < promoTreshHold &&
                      promoCode?.promoCodeType !==
                        item.itemCampaignIdentificator
                    }
                    onClick={() =>
                      getPromoCodeHandler(item.itemCampaignIdentificator)
                    }
                    style={
                      promoCode?.usageLeft < promoTreshHold &&
                      promoCode?.promoCodeType !==
                        item.itemCampaignIdentificator
                        ? { backgroundColor: 'gray', cursor: 'not-allowed' }
                        : {}
                    }
                  >
                    <Typography className="promoBanners__buttonText">
                      {promoCode?.usageLeft < promoTreshHold
                        ? 'Изменить невозможно'
                        : 'ВЫБРАТЬ'}
                    </Typography>
                  </button>
                ) : (
                  <Input
                    value={promoCode.name || ''}
                    disabled
                    className={
                      promoCode?.usageLeft < promoTreshHold
                        ? 'promoDisabled promoBanners__input'
                        : 'promoBanners__input'
                    }
                    suffix={
                      <Tooltip title="Скопировать промокод">
                        <CopyOutlined
                          onClick={() => {
                            navigator.clipboard.writeText(promoCode.name || '');
                            messageApi.success({
                              content: `Промокод: ${promoCode.name} скопирован в буфер обмена!`,
                              duration: 3,
                            });
                          }}
                        />
                      </Tooltip>
                    }
                  />
                ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {promoCode?.promoCodeType === EItemCampaignIdentificators.STANDART && (
        <div className="promoBanners__unAuth">
          <Typography type="description" className="description">
            Вами уже был использован промокод данной акции
          </Typography>
        </div>
      )}
      {!isAuth && (
        <div className="promoBanners__unAuth">
          <Typography type="description" className="description">
            Войдите в систему для получения промокода
          </Typography>
        </div>
      )}
      {contextHolder}
    </section>
  );
};

export default ThreePromoBanners;
