import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';
import { EMediaType } from '1_shared/config/enums/EMediaType';
import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';

export const configMedia: IMediaContentById = {
  id: '1',
  isPrimary: true,
  mediaContentResponse: {
    isPrimary: true,
    mediaType: EMediaType.Image,
    mainMediaLink: DoTherapyLogo,
    previewLink: DoTherapyLogo,
  },
};
