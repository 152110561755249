import { ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { ELoginStatus } from '../../../../1_shared/config/enums/ELoginStatus';
import { Button, Typography } from '../../../../1_shared/ui';

import styles from '../OrderForm.module.scss';
import { IApplicationFormAgreement } from '../interface/IApplicationForm';

/**
 * Отображение итоговой информации о заявке с кнопкой оплаты
 * */
const OrderFormInformationRow = ({
  user,
  promoCode,
  slot,
  status,
  price,
}: {
  user: any;
  promoCode: any;
  slot: any;
  status: any;
  price: any;
}): ReactElement => {
  const { getValues } = useFormContext<IApplicationFormAgreement>();

  const isDisabled = useMemo(
    () =>
      status !== ELoginStatus.None ||
      !user?.id ||
      !getValues('name') ||
      !getValues('agreementTerms') ||
      !getValues('agreementDataProcessing'),
    [
      getValues('name'),
      getValues('agreementTerms'),
      getValues('agreementDataProcessing'),
      status,
      user?.id,
    ],
  );
  return (
    <div className={styles.btnWrapper}>
      <Typography type="subtitle" className={styles.rowText}>
        ИТОГО
      </Typography>
      <div className={styles.costWrapper}>
        <Typography type="title" className={styles.rowText}>
          {`${promoCode && slot ? promoCode.discounted_price : price} ₽`}
        </Typography>
        <Button disabled={isDisabled} htmlType="submit" className={styles.btn}>
          ОПЛАТИТЬ
        </Button>
      </div>
      <Typography type="description">
        После оплаты мы пришлем
        <br />
        вам доступ на платформу
      </Typography>
    </div>
  );
};

export default OrderFormInformationRow;
