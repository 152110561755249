import { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AuthProvider from './AuthProvider';
import FilterProvider from './FilterProvider';
import Routes from './Routes';
import ScrollToHashElement from './ScrollToHashElement';
import ScrollToTop from './ScrollToTop';
import SubscriptionProvider from './SubscriptionProvider';

const PrivateRouter = (): ReactElement => (
  <BrowserRouter>
    <AuthProvider>
      <FilterProvider>
        <SubscriptionProvider>
          <ScrollToHashElement>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </ScrollToHashElement>
        </SubscriptionProvider>
      </FilterProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default PrivateRouter;
