import { TabsProps } from 'antd';

import { SessionsPc } from '3_features';

export const itemsTabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'Сеансы',
    children: <SessionsPc isAsClient />,
  },
  // {
  //   key: '2',
  //   label: 'Избранное',
  //   children: <span>Избранное</span>,
  // },
  // {
  //   key: '3',
  //   label: 'Настройки',
  //   children: <span>Настройки</span>,
  // },
];
