import { mutation } from '1_shared/api/apiInstance';

import { ERequestType } from '../../../../1_shared/api/interfaces/ERequestType';
import { IOtherInformation } from '../../../../1_shared/config/interfaces/IOtherInformation';
import { IUserEditInfo } from '../../../../1_shared/config/interfaces/IUserEditInfo';
import { IClientPreviewOutput } from '../../../../app/api/interfaces/IClientPreviewOutput';
import { IMainInfoOlbBackObject } from '../../ui/MainInfo/interfaces/IMainInfo';

export const editProfileCredentials = mutation<
  IUserEditInfo,
  IClientPreviewOutput
>(ERequestType.Put);
export const editProfileMainInfo = mutation<
  IMainInfoOlbBackObject,
  IClientPreviewOutput
>(ERequestType.Put);
export const editProfileWorkExperience = mutation<any, IClientPreviewOutput>(
  ERequestType.Put,
);
export const editProfileEducation = mutation<any, IClientPreviewOutput>(
  ERequestType.Put,
);
export const editProfileOtherInformation = mutation<
  IOtherInformation,
  IClientPreviewOutput
>(ERequestType.Put);
