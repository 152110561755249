const useGetPromoCode = () => {
  /**
   * Добавление нового промокода в LS пользователя
   * @param {Object} promoCode объект с промокодом
   * @param {string} promoCode.name промокод
   * */
  const addNewPromoCodeInLS = (
    promoCode: { name: string },
    nameOfPromo: string,
  ) => {
    const promoCodesFromLs = JSON.parse(
      localStorage.getItem('promoCodes') as any,
    );

    const newPromoCodesArray = [
      { ...promoCode, promoCodeType: nameOfPromo, usageLeft: 4 },

      // TODO: сделать очистку массива от конкретной промоакции
      // ...(promoCodesFromLs?.promoCodes.filter(
      //   (item: { name: string; promoCodeType: string }) => item.promoCodeType !== nameOfPromo,
      // ) || []),
    ];
    localStorage.setItem('promoCodes', JSON.stringify(newPromoCodesArray));
  };

  /**
   * Получение актуальных промокодов из LS
   * @return {Object}
   * */
  const getPromoCodesFromLS = (): Object =>
    JSON.parse(JSON.parse(localStorage.getItem('promoCodes') || '[]]'));

  return { addNewPromoCodeInLS, getPromoCodesFromLS };
};

export default useGetPromoCode;
