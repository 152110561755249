import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILocationState } from '../../../1_shared/config/interfaces/ILocationState';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { Consultation } from '../../../4_widgets';
import AlertModal from '../../../4_widgets/AlertModal/ui/AlertModal';
import LoginModal from '../../../4_widgets/LoginModal/ui/LoginModal';
import SpecSubscription from '../../../4_widgets/SpecSubscription/ui/SpecSubscription';

const ScrollToHashElement = ({ children }: PropsWithChildren): ReactElement => {
  const { pathname, state: locationState, hash } = useLocation();
  const state = locationState as ILocationState;
  const [hashState, setHashState] = useState(hash);
  const removeHashCharacter = (str: string) => str.slice(1);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { isSubscribed } = useContext(SpecSubContext);

  const onClose = (e: any) => {
    e?.preventDefault();
    if (state?.needSlotSubscribeAfterLogin) {
      navigate(pathname.replace(hash, ''), {
        state: {
          ...state,
          needSlotSubscribeAfterLogin: false,
        },
      });
    }
    navigate(pathname.replace(hash, ''), { state });
  };

  const scrollToHashElement = useCallback((hash: string) => {
    const element = document.getElementById(removeHashCharacter(hash));

    if (hash && !!element) {
      element?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }, []);

  useEffect(() => {
    setHashState(state?.anchor || '');
  }, [state?.anchor]);

  window.onpopstate = () => {
    setTimeout(() => {
      scrollToHashElement(hashState);
    }, 200);
  };

  useEffect(() => {
    if (hashState || hash) {
      scrollToHashElement(hashState || hash);
    }
  }, [hash, hashState, scrollToHashElement, state]);

  return (
    <section>
      {children}
      <Consultation
        open={hash === '#application'}
        closable
        footer={null}
        onCancel={onClose}
      />
      <SpecSubscription
        open={hash === '#specSubscription'}
        isSubscribed={isSubscribed}
        closable
        footer={null}
        onCancel={onClose}
      />
      <AlertModal
        open={hash === '#userNotAuthorized' && !user.role}
        closable
        footer={null}
        onCancel={onClose}
      />
      <LoginModal
        open={hash === '#login' && !user.role}
        showLoginForm
        closable
        footer={null}
        onCancel={onClose}
      />
      <LoginModal
        open={hash === '#register' && !user.role}
        showLoginForm={false}
        closable
        footer={null}
        onCancel={onClose}
      />
    </section>
  );
};

export default ScrollToHashElement;
