import { PropsWithChildren, ReactElement, useState } from 'react';

import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';

const SubscriptionProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [userMessageType, setUserMessageType] = useState<EMessageType | null>(
    null,
  );
  const [specSubName, setSpecSubName] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [specId, setSpecId] = useState('');

  return (
    <SpecSubContext.Provider
      value={{
        userMessageType,
        setUserMessageType,
        specSubName,
        setSpecSubName,
        isSubscribed,
        setIsSubscribed,
        specId,
        setSpecId,
      }}
    >
      {children}
    </SpecSubContext.Provider>
  );
};

export default SubscriptionProvider;
