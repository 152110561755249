import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { getUtm } from '../../../1_shared/helpers/getUtm';
import { IAuthorizeSchema } from '../config/schems';
import { isPhoneField } from '../lib/checkPhone';

import { IHookSubmitOutput } from './intreface/submit/IHookSubmitOutput';
// eslint-disable-next-line import/no-cycle
import useLogin from './useLogin';
// eslint-disable-next-line import/no-cycle
import useRegister from './useRegister';

export interface ISendAuthorizeData extends IAuthorizeSchema {
  contactType: EMessageType;
  utm?: any;
}

const useSubmit = (
  onClose: any,
  isNeedRedirect = true,
  customRoute = null,
  showLoginForm = true,
): IHookSubmitOutput => {
  const { jwt, email } = useParams();
  const isPasswordPage = Boolean(jwt && email);
  const [isLogin, setIsLogin] = useState<boolean>(showLoginForm);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [loginStatus, setLoginStatus] = useState<ELoginStatus>(
    ELoginStatus.None,
  );
  const { login, errorTimerMessage, setErrorTimerMessage, sendCode } = useLogin({
    setLoginStatus,
    status: loginStatus,
    onClose,
  });

  const {
    register,
    errorMessage: regErrorMessage,
    setErrorMessage: setRegErrorMessage,
  } = useRegister({
    status: loginStatus,
    setLoginStatus,
    isPasswordPage,
    jwt: String(jwt),
    email: String(email),
    onClose,
  });

  const resetStates = (onChange: (value: string) => void, value: string) => {
    if (loginStatus !== ELoginStatus.None) setLoginStatus(ELoginStatus.None);
    if (regErrorMessage !== null) setRegErrorMessage(null);
    if (errorTimerMessage !== null) setErrorTimerMessage(null);
    onChange(value);
  };

  const onSubmit = async (data: IAuthorizeSchema) => {
    const utm = getUtm();
    const tempData: Partial<ISendAuthorizeData> = {
      ...data,
      login: isPhoneField(data.login)
        ? data.login.replace('_', '')
        : data.login,
      contactType: isPhoneField(data.login)
        ? EMessageType.PHONE
        : EMessageType.EMAIL,
    };
    if (isLogin && !isPasswordPage) {
      setIsRoleSelected(true);
      await login(tempData, isNeedRedirect, customRoute);
    } else {
      await register(
        { ...tempData, utm: utm || undefined },
        isNeedRedirect,
        customRoute,
      );
    }
  };

  return {
    setIsLogin,
    onSubmit,
    resetStates,
    loginStatus,
    isLogin,
    isPasswordPage,
    loginError: errorTimerMessage,
    regErrorMessage,
    sendCode,
    isRoleSelected,
  };
};

export default useSubmit;
