import { useCallback, useEffect, useRef, useState } from 'react';

import { isPhoneField } from '4_widgets/LoginForm/lib/checkPhone';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { phoneFormConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { Button, MaskInput, Typography } from '../../../1_shared/ui';

import { IPhoneOrderInputProps } from './interfaces/IPhoneOrderInputProps';

import styles from './PhoneInput.module.scss';

const PhoneOrderInput = ({
  user,
  setErrorTimerMessage,
  status,
  setStatus,
  value,
  onChange,
  handleChangePhone,
  setIsLogin,
  foundUser,
  setOpenCode,
  errorTimerMessage,
}: IPhoneOrderInputProps) => {
  const [timer, setTimer] = useState<number>(0);

  const userByAnketa = useRef(
    JSON.parse(localStorage.getItem('userByAnketa') || '{}'),
  );

  const timerFunc = useCallback(() => {
    let interval: string | number | NodeJS.Timer | undefined;
    if (errorTimerMessage) {
      setTimer(Number(errorTimerMessage?.match(/\d+/g)?.join('')));
      interval = setInterval(() => {
        if (timer >= 0) {
          setTimer(prev => prev - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [errorTimerMessage, timer]);

  useEffect(() => {
    timerFunc();
  }, [errorTimerMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(prev => prev - 1);
      }
    }, 1000);

    if (timer <= 0) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [setTimer, timer, status]);

  const isUserExist = user?.id;
  const isAnketaDataExist = userByAnketa.current?.phone;

  return (
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        ТЕЛЕФОН
      </Typography>
      <div className={styles.column}>
        <MaskInput
          disabled={
            (isUserExist ||
              isAnketaDataExist ||
              status !== ELoginStatus.None) &&
            value &&
            isPhoneField(value)
          }
          size="middle"
          value={value}
          suffix={
            !isUserExist && (
              <Button
                onClick={() => {
                  setStatus(ELoginStatus.None);
                  setIsLogin(true);
                  onChange('');
                  setErrorTimerMessage(null);
                }}
                type="text"
                className={styles.btnClose}
              >
                <div className={styles.closeIcon} />
              </Button>
            )
          }
          onChange={e => {
            const maskPhone = phoneFormConvert(e.target.value);
            onChange(maskPhone);
            handleChangePhone(maskPhone || '');
          }}
          mask=""
        />
        {errorTimerMessage && timer >= 0 && (
          <Typography type="description">{`Повторно выслать код можно через ${timer}`}</Typography>
        )}
      </div>
      {((errorTimerMessage && status !== ELoginStatus.CodeSend) ||
        status === ELoginStatus.NeedApprove) && (
        <Button
          disabled={timer > 0}
          onClick={() => {
            foundUser(value || '');
          }}
        >
          ОТПРАВИТЬ КОД
        </Button>
      )}
      {status === ELoginStatus.CodeSend && (
        <Button type="secondary" onClick={async () => setOpenCode(true)}>
          ВВЕСТИ КОД
        </Button>
      )}
      {value && status === ELoginStatus.CodeSend && !user && (
        <Button onClick={() => setOpenCode(true)}>ВВЕСТИ КОД</Button>
      )}
    </div>
  );
};

export default PhoneOrderInput;
