import * as yup from 'yup';

export const schema = yup.object().shape({
  dates: yup.array<{}, string>().required(),
  dayStartAt: yup.string().required(),
  dayEndAt: yup.string().required(),
  breakStartAt: yup
    .string()
    .nullable()
    .test(
      'breakStartAt-required-if-breakEndAt',
      'Введите начало перерыва',
      function checkBreakEnd(value) {
        const { breakEndAt } = this.parent;
        if (breakEndAt && !value) {
          return false;
        }
        return true;
      },
    ),
  breakEndAt: yup
    .string()
    .nullable()
    .test(
      'breakEndAt-required-if-breakStartAt',
      'Введите конец перерыва',
      function checkBreakStart(value) {
        const { breakStartAt } = this.parent;
        if (breakStartAt && !value) {
          return false;
        }
        return true;
      },
    ),
  duration: yup.string().required(),
});
