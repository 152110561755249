import { ReactElement } from 'react';

import { TPromoCode } from '../../../../../1_shared/config/types/TPromoCode';
import { EItemCampaignIdentificators } from '../../../../../1_shared/constants/promoCampaigns/NewYear2024';
import { Typography } from '../../../../../1_shared/ui';

const campaignIdentifiers = [
  EItemCampaignIdentificators.ACQUAINTANCE_AND_DIVE,
  EItemCampaignIdentificators.ACQUAINTANCE_DIVE_AND_GROWTH,
];

const PromoCodeDescription = ({
  promoCode,
}: {
  promoCode: { discount: number; type: EItemCampaignIdentificators };
}): ReactElement => {
  const promoCodes = JSON.parse(localStorage.getItem('promoCodes') || '[]');
  const actualPromoCode =
    promoCodes?.find((item: TPromoCode) => item?.name) ?? '';
  const isSecondPromoProgram =
    promoCode?.type === EItemCampaignIdentificators.ACQUAINTANCE_AND_DIVE;
  const isThirdPromoProgram =
    promoCode?.type ===
    EItemCampaignIdentificators.ACQUAINTANCE_DIVE_AND_GROWTH;

  const programsLeft = `${isSecondPromoProgram ? '4 сеанса' : ''}${isThirdPromoProgram ? '9 сеансов' : ''}`;
  const descriptionByProgram = `По этому промокоду у Вас скидка на единовременную оплату ${isSecondPromoProgram ? 5 : 10} сеансов. После оплаты Вы получите промокод на 100% скидку для записи еще на ${programsLeft}.`;
  const descriptionText = promoCode ? (
    <>
      {Object.values(campaignIdentifiers).includes(promoCode.type) &&
        actualPromoCode?.usageLeft < 2 &&
        descriptionByProgram}
      {`Ваша скидка - ${promoCode.discount} ₽`}
    </>
  ) : (
    'Код из подарочного сертификата тоже сюда'
  );

  return <Typography type="description">{descriptionText}</Typography>;
};

export default PromoCodeDescription;
