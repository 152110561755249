import {
  MutableRefObject,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { ELoginStatus } from '../../../../../1_shared/config/enums/ELoginStatus';
import { ERoles } from '../../../../../1_shared/config/enums/ERoles';
import { ISpecialistData } from '../../../../../1_shared/config/interfaces/ISpecialistData';
import { phoneConvert } from '../../../../../1_shared/lib/helpers/phoneConvert';
import { IClientPreviewOutput } from '../../../../../app/api/interfaces/IClientPreviewOutput';
import { EOrderFormType } from '../../constants/EOrderFormType';
import { IApplicationFormAgreement } from '../../interface/IApplicationForm';
import { IHookPhoneLoginOutput } from '../../model/interfaces/IHookPhoneLoginOutput';
import usePhoneLogin from '../../model/usePhoneLogin';

import OrderFormForClients from './OrderFormForClients';

interface IOrderFormByType {
  formType: EOrderFormType;
  userByAnketa: MutableRefObject<{ name: string; phone: string }>;
  spec: any;
  isLoading: boolean;
}

/**
 * Компонент для определения какая форма должна быть на странице заявки на сессию
 * @param {Object} properties
 * @param {EOrderFormType} properties.formType параметр для определения типа формы
 * @param {{name: string; phone: string;}} properties.userByAnketa объект для автозаполнения если пользователь ранее проходил анкету
 * @param {ISpecialistShortData} properties.spec объект с данными о специалисте
 * @param {boolean} properties.isLoading статус загрузки данных о специалисте
 * */
const OrderFormByType = ({
  formType,
  userByAnketa,
  spec,
  isLoading,
}: IOrderFormByType): ReactElement => {
  const { reset, getValues } = useFormContext<IApplicationFormAgreement>();

  const {
    openCode,
    setOpenCode,
    status,
    foundUser,
    handleChangePhone,
    registerUser,
    updateNickname,
    setIsLogin,
    setStatus,
    isLogin,
    errorTimerMessage,
    setErrorTimerMessage,
  } = usePhoneLogin();

  const loginFieldProps: Partial<IHookPhoneLoginOutput> = {
    openCode,
    setOpenCode,
    status,
    foundUser,
    handleChangePhone,
    registerUser,
    updateNickname,
    setIsLogin,
    setStatus,
    isLogin,
    errorTimerMessage,
    setErrorTimerMessage,
  };

  const currentRole = localStorage.getItem('role') || ERoles.Client;

  const user = useMemo(
    (): IClientPreviewOutput | ISpecialistData | null =>
      JSON.parse(localStorage.getItem('user') || '{}'),
    [localStorage.getItem('user')],
  );

  const fullName =
    `${user?.secondName || ''} ${user?.firstName || ''} ${(user as any)?.patronymic || ''}`.trim();

  const nameFieldValue = userByAnketa.current?.name || fullName;

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    reset({
      login:
        userByAnketa.current?.phone ?? (phoneConvert(user?.phone || '') || ''),
      name:
        userByAnketa.current?.name ??
        (user?.nickname && user?.nickname !== 'Аноним'
          ? user?.nickname
          : nameFieldValue),
      code: undefined,
      agreementTerms: getValues('agreementTerms'),
      agreementDataProcessing: getValues('agreementDataProcessing'),
    });
    if (userByAnketa.current?.phone && status !== ELoginStatus.CodeVerified) {
      setStatus(ELoginStatus.NeedApprove);
    }
    if (user?.id) {
      setStatus(ELoginStatus.None);
      setIsLogin(true);
      setErrorTimerMessage(null);
    }
  }, [user, reset, currentRole, setStatus, setIsLogin, setErrorTimerMessage]);

  const getForm = (formType: EOrderFormType) => {
    switch (formType) {
      case EOrderFormType.CREATE_ORDER: // TODO: возвращать форму для создания сессии для клиента от роли спеца [DOT-362]
      case EOrderFormType.PAYMENT_FORM:
      case EOrderFormType.SESSION_ORDER:
      default:
        return (
          <OrderFormForClients
            user={user}
            slotId={getValues('slotId')}
            updateNickname={updateNickname}
            loginFieldProps={loginFieldProps}
            status={status}
            spec={spec}
            isLoading={isLoading}
            disable={formType === EOrderFormType.PAYMENT_FORM}
          />
        );
    }
  };
  return getForm(formType);
};

export default OrderFormByType;
