import { Link } from 'react-router-dom';

import { RoutePath } from '../../../../1_shared/config/routes';
import { Typography } from '../../../../1_shared/ui';

export const breadCrumbsOrderForm = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">DOTHERAPY</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ</Typography>
      </Link>
    ),
  },
  {
    title: <Typography type="description">ЗАПИСЬ НА СЕССИЮ</Typography>,
  },
];
