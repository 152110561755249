import { TabsProps } from 'antd';

import { Schedule } from '../../../3_features';
import SessionsPc from '../../../3_features/SessionsPc/ui/SessionsPc';
import SpecialistSessionsTable from '../../../3_features/SpecialistSessionsTable/ui/SpecialistSessionsTable';

export const itemsTabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'Я специалист',
    children: <SessionsPc />,
  },
  {
    key: '2',
    label: 'Я клиент',
    children: <SessionsPc isAsClient/>,
  },
  {
    key: '3',
    label: 'Расписание',
    children: <Schedule />,
  },
  {
    key: '4',
    label: 'История',
    children: <SpecialistSessionsTable />,
  },
];
