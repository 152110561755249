import { PropsWithChildren, ReactElement } from 'react';

import { AuthContext } from '../lib/context/AuthContext';
import { useAuth } from '../lib/hooks/useAuth';

const AuthProvider = ({ children }: PropsWithChildren): ReactElement => {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
