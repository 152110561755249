import React from 'react';
import { Flex, Select } from 'antd';
import cn from 'classnames';

import { Label } from '1_shared/ui/Label';

import { ICustomSelectProps } from './ICustomSelectProps';

import styles from './CustomSelect.module.scss';
import "./Styles.scss";

const CustomSelect: React.ForwardRefExoticComponent<
  ICustomSelectProps & React.RefAttributes<any>
> = React.forwardRef(
  (
    {
      className,
      placeholder = 'Выберите',
      // Заполнение цветом
      hasFilledUpColor,
      label = '',
      name = '',
      onChange,
      ...props
    },
    ref,
  ) => (
    <Flex vertical>
      <Label name={name} label={label} />
      <Select
        {...props}
        className={cn(styles.select, className, {
          [styles.filledUp]: hasFilledUpColor,
        })}
        placeholder={placeholder}
        ref={ref}
        id={name}
        onChange={onChange}
      />
    </Flex>
  ),
);

export default CustomSelect;
