import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import useMessage from 'antd/es/message/useMessage';
import cn from 'classnames';
import useSWRMutation from 'swr/mutation';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { EContactType } from '1_shared/config/enums/EContactType';
import { dayTypeMapping } from '1_shared/config/enums/EDayType';
import { expWithPsyMapping } from '1_shared/config/enums/EExpWithPsy';
import { psychoGenderMapping } from '1_shared/config/enums/EPsychoGender';
import { wellBeingStatusMapping } from '1_shared/config/interfaces/EWellBeing';
import { IDictionaryTheme } from '1_shared/config/interfaces/IDictionaryTheme';
import { getUtm } from '1_shared/helpers/getUtm';
import { Button, Tabs } from '1_shared/ui';

import { ESessionFormType } from '../../../1_shared/api/interfaces/ESessionFormType';
import { dayTimeConfig } from '../../../1_shared/config/dayTimeConfig';
import {
  dayTimeMapping,
  EDayTime,
} from '../../../1_shared/config/enums/EDayTime';
import { IAnketaForm } from '../../../1_shared/config/interfaces/IAnketaForm';
import { ILocationState } from '../../../1_shared/config/interfaces/ILocationState';
import { RoutePath } from '../../../1_shared/config/routes';
import { apiLinksForNotification } from '../../../1_shared/constants/apiLinksByEnv';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { getSpecialistList } from '../../../5_pages/SpecialistsPage/model/service/specService';
import { itemsForm } from '../config/itemsConfig';
import { IAnketaFormSchema } from '../config/schema';

import styles from './AnketaTabs.module.scss';

const AnketaTabsForm = () => {
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') || '')
    : null;
  const [isDone, setIsDone] = useState(false);
  const [messageApi, contextHolder] = useMessage();

  const { state: locationState, search } = useLocation();
  const state = locationState as ILocationState;
  const activeTabParam = new URLSearchParams(search).get('actionTab');

  const [activeTab, setActiveTab] = useState<string | number>(
    '4' ?? // TODO: эксперимент - оставить только вкладку с контактной информацией
      (state?.actionTab as string) ??
      activeTabParam ??
      '1',
  );

  const { data: dictionaryThemes } = useSWRWithCache(
    '/spec/key-themes',
    getDictionaries,
  );

  const methods = useForm<IAnketaForm>({
    defaultValues: {
      phone: phoneConvert(user?.phone || ''),
      name: `${user?.firstName || ''} ${user?.patronymic || ''} ${user?.secondName || ''} ${user?.nickname || ''}`.trim(),
      chooseContactType: EContactType.WhatsApp,
    },
    resolver: zodResolver(IAnketaFormSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const navigate = useNavigate();
  const goNext = () => {
    if (activeTab === '2') {
      // @ts-ignore
      ym(97788822, 'reachGoal', 'anketa_podbor_2page', {
        isAuthorize: !!user,
        userId: user?.id ?? null,
      });
    }
    if (activeTab === '3') {
      // @ts-ignore
      ym(97788822, 'reachGoal', 'anketa_podbor_3page', {
        isAuthorize: !!user,
        userId: user?.id ?? null,
      });
    }
    setActiveTab(prev => String(Number(prev) + 1));
  };

  const goPrevious = () => {
    setActiveTab(prev => String(Number(prev) - 1));
  };

  const transformKeyThemes = (
    keyThemes: string[],
    dictionaryThemes: IDictionaryTheme[],
  ): Record<string, string | null> => {
    const categories: Record<string, string> = {
      sex_and_gender: 'sexGender',
      self_rating: 'selfRating',
      psychosomatics: 'psychosomatics',
      relationships: 'relationship',
      emotional_state: 'emotional',
      life_events: 'liveEvents',
      motivation: 'motivation',
    };

    const preResult: Record<string, string[]> = {
      sexGender: [],
      selfRating: [],
      psychosomatics: [],
      relationship: [],
      emotional: [],
      liveEvents: [],
      motivation: [],
    };

    keyThemes.forEach(theme => {
      const foundTheme = dictionaryThemes.find(item => item.value === theme);
      if (foundTheme) {
        const category = categories[foundTheme.categoryNameValue];
        if (category) {
          preResult[category].push(foundTheme.label);
        }
      }
    });

    const result: Record<string, string | null> = {};

    Object.keys(preResult).forEach(key => {
      const joinedResult = preResult[key].join(', ');
      result[key] = joinedResult === '' ? null : joinedResult;
    });

    return result;
  };

  const { trigger } = useSWRMutation(
    `${apiLinksForNotification}/ns/api/v1/system-notifications/session-form`,
    getSpecialistList,
    {
      onError: () => {
        messageApi.error({
          content:
            'С отправкой вашей заявки что-то пошло не так. Обратитесь в поддержку!',
        });
        setIsDone(false);
      },
    },
  );

  const getData = async (data: any) => {
    await trigger(data);
  };

  const onSubmit = async (data: IAnketaForm) => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'anketa_podbor_sendclick', {
      isAuthorize: !!user,
      userId: user?.id ?? null,
    });
    const keyThemes = data.keyThemas
      ? transformKeyThemes(data.keyThemas, dictionaryThemes!)
      : undefined;
    const utm = getUtm();
    localStorage.setItem(
      'userByAnketa',
      JSON.stringify({
        name: data?.name.trim(),
        phone: data?.phone,
      }),
    );
    const requestData = {
      // TODO: need fix any as requestTypeObject
      ...data,
      keyThemes,
      name: data?.name.trim(),
      wellBeing: data.wellBeing
        ? (wellBeingStatusMapping as any)[data.wellBeing]
        : null,
      convenientTime: data.time ? (dayTimeMapping as any)[data.time] : null,
      convenientDays: data.dayType
        ? (dayTypeMapping as any)[data.dayType]
        : null,
      psychologistExpWorking: data.haveExpWithPsy
        ? (expWithPsyMapping as any)[data.haveExpWithPsy]
        : null,
      preferredGenderPsycho: data.sex
        ? (psychoGenderMapping as any)[data.sex]
        : null,
      preferredContactType: data.chooseContactType,
      sessionType:
        ((state?.actionTab as string) ?? activeTabParam)
          ? ESessionFormType.FIND_SPECIALIST
          : ESessionFormType.INTERVIEW,
      utm,
      telegram: data?.telegram ? `@${data.telegram}` : null,
    };

    setIsDone(true);
    await getData(requestData);

    const tempData = {
      experienceSortOrder: 'DESC',
      slotsFilter: {
        dayType: data.dayType || null,
        ...dayTimeConfig[data.time as EDayTime],
      },
      keyThemes: data.keyThemas || undefined,
      sex: data.sex || null,
    };

    // @ts-ignore
    ym(97788822, 'reachGoal', 'anketa_podbor_success', {
      isAuthorize: !!user,
      userId: user?.id ?? null,
    });
    localStorage.removeItem('utm');
    navigate(RoutePath.SPECIALISTS, {
      state: { filters: tempData },
    });
  };

  const tabsForm = useMemo(
    () => itemsForm?.slice(Number(activeTab) - 1) ?? [], // TODO: эксперимент с анкетой
    [],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  return (
    <section className={styles.testing__inner}>
      <FormProvider {...methods}>
        <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            classNameTabs={styles.tabs}
            items={tabsForm}
            activeTab={activeTab}
            defaultActiveTabIndex={activeTab ?? 1}
            setActiveTab={setActiveTab}
          />
          <footer
            className={cn(styles.btnWrapper, {
              [styles.btnFirstWrap]: tabsForm && activeTab === tabsForm[0].key,
            })}
          >
            {tabsForm && activeTab !== tabsForm[0].key && (
              <Button
                className={styles.btnPrevios}
                type="secondary"
                onClick={goPrevious}
              >
                НАЗАД
              </Button>
            )}
            {tabsForm && activeTab !== tabsForm[tabsForm.length - 1].key && (
              <Button
                className={styles.btnNext}
                type="primary"
                onClick={goNext}
              >
                ДАЛЕЕ
              </Button>
            )}
            {tabsForm && activeTab === tabsForm[tabsForm.length - 1].key && (
              <Button
                htmlType="submit"
                className={styles.btnSubmit}
                type="primary"
                disabled={!isValid || isDone}
              >
                {isDone ? 'ЗАЯВКА ОТПРАВЛЕНА!' : 'ПОДОБРАТЬ СПЕЦИАЛИСТА'}
              </Button>
            )}
          </footer>
        </form>
      </FormProvider>
      {contextHolder}
    </section>
  );
};

export default AnketaTabsForm;
