import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import { RoutePath } from '1_shared/config/routes';
import { Page } from '1_shared/ui';

import { specApiInstance } from '../../1_shared/api/apiInstance';
import LogoSvg from '../../1_shared/assets/pictures/logo.svg';
import LogoMobileSvg from '../../1_shared/assets/pictures/logo-mobile.svg';
import LogoMIPSvg from '../../1_shared/assets/pictures/MIP.svg';
import { ERoles } from '../../1_shared/config/enums/ERoles';
import { UserRoutes } from '../../1_shared/constants/userRoutes';
import { useAuthContext } from '../../app/module/lib/hooks/useAuthContext';

import styles from './Oauth.module.scss';

/** Страница для проверки входа по SSO */
const Oauth = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location as {
    state: { pathname?: string; state: unknown };
  };

  const { login } = useAuthContext();

  const searchParams = new URLSearchParams(location.search);
  const codeParam = searchParams.get('code');
  const stateParam = searchParams.get('state');

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [percent, setPercent] = useState(-50);
  const [auto] = useState(false);
  const mergedPercent = auto ? 'auto' : percent;

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setPercent(v => {
        const nextPercent = v + 2;
        return nextPercent > 150 ? -50 : nextPercent;
      });
    }, 100);
    return () => clearTimeout(timerRef.current);
  }, [percent]);

  const fetchData = async () => {
    try {
      const response = await specApiInstance.get(
        '/aim/login/oauth2/code/instudy',
        {
          params: {
            code: codeParam,
            state: stateParam,
          },
        },
      );

      localStorage.setItem('role', response.data.roles);

      /** Организация подписки после входа по SSO */
      const subscribeSlotData = JSON.parse(
        localStorage.getItem('subscribeSlotData') ||
          '{needSlotSubscribeAfterLogin:false}',
      );
      if (subscribeSlotData?.needSlotSubscribeAfterLogin) {
        localStorage.removeItem('subscribeSlotData');
        navigate('/specialists', {
          state: { ...(state || {}), ...subscribeSlotData },
        });
      }

      /** Редирект на страницу профиля при обычной авторизации пользователя */
      if (response && response.data?.roles && login) {
        login(response.data?.roles);
        navigate(UserRoutes[response.data.roles as ERoles], {
          state: { ...(state?.state || {}), needReload: true },
        });
      }
    } catch (error) {
      console.error('oauth', error);
      navigate('/login', {
        state: {
          ...state,
          content: 'Ошибка получения данных',
        },
      });
    }
  };

  useEffect(() => {
    if (!codeParam || !stateParam) {
      navigate('/login', {
        state: {
          content: 'Ошибка получения данных',
        },
      });
      return;
    }
    fetchData();
  }, []);

  return (
    <Page>
      <div className={styles.root}>
        <div>
          <div className={styles.wrapperLogos}>
            <Link to={RoutePath.MAIN}>
              <source media="(max-width: 1023px)" srcSet={LogoMobileSvg} />
              <img src={LogoSvg} alt="DoTherapy" width="200" height="39" />
            </Link>
            <a
              href="https://inpsycho.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mipLogo}
            >
              <source media="(max-width: 1023px)" srcSet={LogoMIPSvg} />
              <img src={LogoMIPSvg} alt="DoTherapy" width="200" height="39" />
            </a>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Spin percent={auto ? 'auto' : mergedPercent} size="large" />
          Идет проверка доступности сесии...
        </div>
      </div>
    </Page>
  );
};

export default Oauth;
