import { ReactElement, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as Arrow } from '1_shared/assets/pictures/arrowRight.svg';
import { Button, Content, Typography } from '1_shared/ui';
import { PreviewImage, SessionTable, TimerSession } from '3_features';

import DotsAnketaMp4 from '../../../1_shared/assets/video/dots_anketa.mp4';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { RoutePath } from '../../../1_shared/config/routes';
import { TabContext } from '../../../1_shared/ui/CustomTabs/module/provider/TabContext';
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { ETypeTable } from '../../SessionsTable/config/interfaces/ETypeTable';
import useInfoSessions from '../model/useInfoSessions';

import styles from './SessionPc.module.scss';

const SessionsPc = ({ isAsClient = false }): ReactElement => {
  const { user } = useAuthContext();
  const { activeSession, futureSessions, pastSessions, isLoading, mutate } =
    useInfoSessions(isAsClient);

  const { setActiveTab } = useContext(TabContext);
  const navigate = useNavigate();

  const role = localStorage.getItem('role');
  const isSpecialist = role === ERoles.Spec;
  if (isLoading) {
    return (
      <div className={styles.root}>
        <Spin />
      </div>
    );
  }

  const renderContent = () => {
    if (futureSessions?.length) {
      if (isSpecialist) {
        return (
          <button
            type="button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setActiveTab?.('4');
            }}
            className={styles.goToHistory}
          >
            <Typography type="description">Все сеансы</Typography>
            <Arrow />
          </button>
        );
      }
      return null;
    }
    return <Typography>Вы еще не были на сеансах</Typography>;
  };
  return (
    <div className={styles.root}>
      {activeSession && (
        <div className={styles.now}>
          <div className={styles.sessionNow}>
            <div className={styles.titleNow}>
              <Typography type="description">АКТИВНЫЙ СЕАНС</Typography>
            </div>
            <div className={styles.cardNow}>
              <PreviewImage
                className={styles.img}
                noAvatar={!(isSpecialist && activeSession?.specialist?.avatar)}
                media={
                  isSpecialist && activeSession?.specialist?.avatar
                    ? {
                        id: '1',
                        ...configMedia.mediaContentResponse,
                        previewLink: activeSession?.specialist?.avatar,
                      }
                    : { id: '1', ...configMedia.mediaContentResponse }
                }
              />
              <div className={styles.info}>
                <div className={styles.nameBlock}>
                  <Typography type="title">
                    {isSpecialist
                      ? activeSession?.clientNickname
                      : activeSession?.specialist?.lastName}
                  </Typography>
                  {!isSpecialist && (
                    <Typography type="title">
                      {activeSession?.specialist?.firstName}
                    </Typography>
                  )}
                  <Typography type="p" className={styles.weightTitle}>
                    уже ожидает вас
                  </Typography>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.textWrap}>
                    <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
                    <Typography>
                      {dayjs(
                        activeSession?.start,
                        'YYYY-MM-DDTHH:mm:ss',
                      ).format('DD MMMM, HH:mm')}
                    </Typography>
                  </div>
                  <TimerSession end={activeSession.end} mutate={mutate} />
                </div>
              </div>
              <div className={styles.btnWrap}>
                <Button
                  className={styles.bigBtn}
                  onClick={() =>
                    navigate(`${RoutePath.CONFERENCE}/${activeSession.slotId}`)
                  }
                >
                  {isSpecialist ? 'НАЧАТЬ СЕАНС' : 'ПРИСОЕДИНИТЬСЯ'}
                </Button>
              </div>
            </div>

            <div className={styles.videoWrap}>
              <video
                src={DotsAnketaMp4}
                className={styles.videoItem}
                width="100%"
                height="auto"
                playsInline
                muted
                loop
                autoPlay
                preload="auto"
              />
            </div>
          </div>

          <Content wrapperClass="paddingTopNone">
            <div className={styles.fixData}>
              <Typography>Заметили ошибку? Поможем исправить</Typography>
              <a
                href="https://t.me/do_therapy"
                className="btn btn--light"
                type="primary"
              >
                ПЕРЕЙТИ В ТЕЛЕГРАММ
              </a>
            </div>
          </Content>
        </div>
      )}
      <Content wrapperClass="paddingTopNone">
        <div className={styles.tableWrapper}>
          <div className={styles.headerTitle}>
            <Typography type="title">Будущие сеансы</Typography>
            {renderContent()}
          </div>
          {futureSessions && !!futureSessions.length && (
            <SessionTable
              isAsClient={isAsClient}
              mutate={mutate}
              items={futureSessions || []}
              typeTable={ETypeTable.Future}
            />
          )}
          {isAsClient && !futureSessions?.length && (
            <div className={styles.notFoundSessions}>
              <Typography>В данный момент у вас нет сеансов</Typography>
              <Link
                to={RoutePath.SPECIALISTS}
                state={{ anchor: 'survey', actionTab: '2' }}
                className="hero__btn btn btn--violet"
                onClick={() =>
                  // @ts-ignore
                  ym(97788822, 'reachGoal', 'profile_podbor_click', {
                    isAuthorize: !!user,
                    userId: user?.id ?? null,
                  })
                }
              >
                Подобрать специалиста
              </Link>
            </div>
          )}
        </div>
      </Content>
      <Content wrapperClass="paddingTopNone">
        <div className={styles.tableWrapper}>
          <div className={styles.headerTitle}>
            <Typography type="title">Прошедшие сеансы</Typography>
            {!!pastSessions?.length && isSpecialist && (
              <button
                type="button"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                  setActiveTab?.('4');
                }}
                className={styles.goToHistory}
              >
                <Typography type="description">Все сеансы</Typography>
                <Arrow />
              </button>
            )}
          </div>
          {pastSessions && !!pastSessions.length ? (
            <SessionTable
              isAsClient={isAsClient}
              mutate={mutate}
              items={pastSessions || []}
              typeTable={ETypeTable.Past}
            />
          ) : (
            <Typography>Вы еще не были на сеансах</Typography>
          )}
        </div>
      </Content>
    </div>
  );
};

export default SessionsPc;
