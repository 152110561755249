import { Typography } from '../../../1_shared/ui';

import { ISpecialistAvatarProps } from './interfaces/ISpecialistAvatarProps';

import styles from './SpecialistAvatar.module.scss';
import { ReactElement } from 'react';

// TODO: Change component for Client
const SpecialistAvatar = ({
  firstName,
  lastName,
  patronymic,
  imgPreview,
  specIdentifier,
}: ISpecialistAvatarProps): ReactElement => (
    <div className={styles.root}>
      {specIdentifier ? (
        <a href={`/specialists/specialist/${specIdentifier}`}>
          <div className={styles.wrapperAvatar}>
            <img src={imgPreview} alt="avatar" className={styles.imgAvatar} />
          </div>
        </a>
      ) : (
        <div className={styles.wrapperAvatar}>
          <img src={imgPreview} alt="avatar" className={styles.imgAvatar} />
        </div>
      )}
      {specIdentifier ? (
        <a href={`/specialists/specialist/${specIdentifier}`}>
          <Typography>{`${lastName ?? ''} ${firstName ?? ''} ${patronymic ?? ''}`}</Typography>
        </a>
      ) : (
        <Typography>{`${lastName ?? ''} ${firstName ?? ''} ${patronymic ?? ''}`}</Typography>
      )}
    </div>
  );

export default SpecialistAvatar;
