import { useEffect, useRef, useState } from 'react';

import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';

import { IVideoProps } from './interfaces/IVideoProps';

import './VideoComponent.scss';

const VideoComponent = ({ item }: IVideoProps) => {
  const { user } = useAuthContext();
  const [play, setPlay] = useState(false);
  const [preview, setPreview] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const endVideo = () => {
    setPreview(true);
    setPlay(false);
  };

  const playVideo = () => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'click_video', {
      isAuthorize: !!user,
      userId: user?.id ?? null,
    });
    if (!preview) {
      setPlay(prev => !prev);
    }
    if (!play) {
      setPlay(true);
    }
    setPreview(false);
  };

  useEffect(() => {
    if (play) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [play]);

  return (
    <div className="video__item">
      {!play && (
        <div className="video__content">
          <span className="video__suptitle">видео</span>
          <h3 className="video__item-title">{item.title}</h3>
        </div>
      )}
      <div className="video__box">
        {!preview ? (
          <video
            playsInline
            ref={videoRef}
            onEnded={endVideo}
            onClick={() => setPlay(false)}
          >
            <source type="video/mp4" src={item.src} />
          </video>
        ) : (
          <img src={item.preview} alt={item.title} />
        )}
      </div>
      {!play && (
        <button
          className="video__button"
          type="button"
          aria-label="play"
          onClick={playVideo}
        >
          <div className="button-play" />
        </button>
      )}
    </div>
  );
};

export default VideoComponent;
