import dayjs from 'dayjs';
import { z } from 'zod';

export const IWorkExperienceSchema = z.object({
  job_experiences: z.array(
    z
      .object({
        company: z
          .string({
            required_error: 'Укажите название компании',
          })
          .min(1, 'Укажите название компании'),
        position: z
          .string({
            required_error: 'Укажите должность в компании',
          })
          .min(1, 'Укажите должность в компании'),
        city: z
          .string({
            required_error: 'Укажите город компании',
          })
          .min(1, 'Укажите город компании'),
        date_from: z.string().optional(),
        date_to: z
          .union([
            z.string({
              message: 'Необходимо указать дату увольнения из компании',
            }),
            z.null(),
          ])
          .optional(),
        is_present_time: z.boolean().optional(),
      })
      .superRefine(({ is_present_time, date_from, date_to, company }, ctx) => {
        if (!dayjs(date_from).isValid()) {
          return ctx.addIssue({
            code: 'custom',
            message: `Некорректно указаны даты работы в компании ${!company ? '' : `«${company}»`}`,
            path: ['date_from'],
          });
        }

        if (!is_present_time) {
          if (!date_to) {
            return ctx.addIssue({
              code: 'custom',
              message: 'Необходимо указать дату увольнения из компании',
              path: ['date_to'],
            });
          }

          if (!dayjs(date_to).isValid() || dayjs(date_from) > dayjs(date_to)) {
            return ctx.addIssue({
              code: 'custom',
              message: `Некорректно указаны даты работы в компании ${!company ? '' : `«${company}»`}`,
              path: ['date_to'],
            });
          }

          if (dayjs(date_to).year() < 1970) {
            return ctx.addIssue({
              code: 'custom',
              message: `Сроки работы не могут быть раньше 1970 года`,
              path: ['date_to'],
            });
          }

          if (dayjs(date_to) > dayjs()) {
            return ctx.addIssue({
              code: 'custom',
              message: `Сроки работ не могут быть больше чем текущая дата`,
              path: ['date_to'],
            });
          }
        }

        if (dayjs(date_from).year() < 1970) {
          return ctx.addIssue({
            code: 'custom',
            message: `Сроки работы не могут быть раньше 1970 года`,
            path: ['date_from'],
          });
        }

        if (dayjs(date_from) > dayjs()) {
          return ctx.addIssue({
            code: 'custom',
            message: `Сроки работ не могут быть больше чем текущая дата`,
            path: ['date_from'],
          });
        }
      }),
  ),
});
