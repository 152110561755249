import { Controller, Path, useFormContext } from 'react-hook-form';

import { IWorkExperienceInfo } from '../../api/interfaces/IWorkExperienceInfo';
import { CustomTypography } from '../CustomTypography';
import { Input, Typography } from '../index';

import styles from './SpecialistEditProfileInput.module.scss';

const SpecialistEditProfileInput = ({
  name,
  label,
  placeholder,
  required = false,
  type,
  disabled = false,
  status,
  max,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  status?: 'error' | 'warning';
  max?: number;
}) => {
  const methods = useFormContext<IWorkExperienceInfo>();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (type === 'number') {
      let numValue = value.replace(/[^0-9]/g, '');
      if (max !== undefined && value.length > max) {
        numValue = value.slice(0, max);
      }
      setValue(name as Path<IWorkExperienceInfo>, numValue);
    } else {
      setValue(name as Path<IWorkExperienceInfo>, value);
    }
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (
        ['e', 'E', '-', '+', '.', ','].includes(event.key) ||
        (event.key === ' ' && event.ctrlKey)
      ) {
        event.preventDefault();
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Typography type="description">
        {label}
        {required && <span className={styles.required_asterisk}>*</span>}
      </Typography>
      <Controller
        name={name as Path<IWorkExperienceInfo>}
        control={control}
        defaultValue={getValues(name as Path<IWorkExperienceInfo>)}
        render={({ field }) => {
          return (
            <>
              {/* @ts-ignore */}
              <Input
                {...field}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                status={status}
                min={0}
              />
              {(errors as any)?.[name] && (
                <CustomTypography type="description" className={styles.error}>
                  {(errors as any)?.[name].message}
                </CustomTypography>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default SpecialistEditProfileInput;
