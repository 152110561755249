const useChangeLocalSubscribeStatus = () => {
  /**
   * Изменить состояние подписки в параметре subscriptions объекта user, который лежит в local storage
   * - Если пользователь подписывается на специалиста, то произойдет добавление specId в параметр subscriptions
   * @param {string} specId - id специалиста
   * */
  const subscribeOnSpec = (specId: string) => {
    const subscriptions = JSON.parse(
      localStorage.getItem('subscriptions') || '[]',
    );

    localStorage.setItem(
      'subscriptions',
      JSON.stringify(Array.from(new Set([...(subscriptions || []), specId]))),
    );
  };

  /**
   * Изменить состояние подписки в параметре subscriptions объекта user, который лежит в local storage
   * - Если пользователь уже подписан на специалиста, то произойдет удаление specId из параметра subscriptions
   * @param {string} specId - id специалиста
   * */
  const unsubscribeOnSpec = (specId: string) => {
    const subscriptions = JSON.parse(
      localStorage.getItem('subscriptions') || '[]',
    );
    if (!!subscriptions?.length && subscriptions?.includes(specId)) {
      localStorage.setItem(
        'subscriptions',
        JSON.stringify(
          subscriptions?.filter((value: string) => value !== specId),
        ),
      );
    }
  };

  const changeSubscriptionsInLS = (subscriptions = []) => {
    localStorage.setItem('subscriptions', JSON.stringify(subscriptions));
  };

  return {
    subscribeOnSpec,
    unsubscribeOnSpec,
    changeSubscriptionsInUserObject: changeSubscriptionsInLS,
  };
};

export default useChangeLocalSubscribeStatus;
