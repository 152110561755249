import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { InvisibleSmartCaptcha, SmartCaptcha } from '@yandex/smart-captcha';

const YandexReactCaptcha = ({
  setToken,
  needInvisible = false,
}: {
  setToken: any;
  needInvisible?: boolean;
}): ReactElement => {
  const { setValue } = useFormContext();

  if (needInvisible)
    return (
      <InvisibleSmartCaptcha
        language="ru"
        sitekey="ysc1_3bbTgLuZcFVMKmfDhqlpSDIXqCSg6PdSE3drkigHd8c1579b"
        onSuccess={token => {
          if (setToken) setToken(token);
        }}
        onTokenExpired={() => setValue('isCaptchaDone', null)}
        onNetworkError={() => setValue('isCaptchaDone', null)}
        onJavascriptError={() => setValue('isCaptchaDone', null)}
      />
    );

  return (
    <SmartCaptcha
      language="ru"
      sitekey="ysc1_3bbTgLuZcFVMKmfDhqlpSDIXqCSg6PdSE3drkigHd8c1579b"
      onSuccess={token => {
        if (setToken) setToken(token);
      }}
      onTokenExpired={() => setValue('isCaptchaDone', null)}
      onNetworkError={() => setValue('isCaptchaDone', null)}
      onJavascriptError={() => setValue('isCaptchaDone', null)}
    />
  );
};
export default YandexReactCaptcha;
