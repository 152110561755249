import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IMediaContentById } from '1_shared/config/interfaces/IMediaContentById';

import specImg1 from '../../../1_shared/assets/images/specialistPhotos/spec1.jpg';
import specImg2 from '../../../1_shared/assets/images/specialistPhotos/spec2.jpg';
import specImg3 from '../../../1_shared/assets/images/specialistPhotos/spec3.jpg';
import PreviewGroupImage from '../../../1_shared/ui/PreviewGroupImage/ui/PreviewGroupImage';
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';
import PreviewImage from '../../PreviewImage/ui/PreviewImage';

import { IPreviewListImageProps } from './interfaces/IPreviewListImageProps';

import styles from './PreviewListImage.module.scss';

const specImgArr = [specImg1, specImg2, specImg3];

const PreviewListImage = ({
  media,
  ...others
}: IPreviewListImageProps): ReactElement => {
  const [currentMedia, setCurrentMedia] = useState<IMediaContentById | null>(
    media.find(el => el.mediaContentResponse.isPrimary) ?? media[0],
  );

  const imgMedia = (img: IMediaContentById) =>
    img
      ? {
          ...img!.mediaContentResponse,
          id: currentMedia!.id,
        }
      : { ...configMedia.mediaContentResponse, id: configMedia.id };

  return (
    <div className={styles.root}>
      <div className={styles.previewMobile}>
        <Swiper
          spaceBetween={20}
          slidesPerView="auto"
          mousewheel
          modules={[Mousewheel]}
          className={styles.previewMobile}
        >
          {media
            ? media.map(img => (
                <SwiperSlide className={styles.swiperSlide} key={img.id}>
                  <PreviewImage
                    alt="person"
                    key={img.mediaContentResponse.previewLink}
                    className={styles.personImg}
                    media={imgMedia(img)}
                    src={img.mediaContentResponse.mainMediaLink}
                    isMobile
                  />
                </SwiperSlide>
              ))
            : specImgArr.map(img => (
                <PreviewImage
                  alt="person"
                  key={img}
                  className={styles.personImg}
                  media={imgMedia(currentMedia!)}
                  src={img}
                  isMobile
                />
              ))}
        </Swiper>
      </div>
      <div className={styles.previewDesktop}>
        <div className={styles.preview}>
          <PreviewGroupImage
            {...others}
            currentMedia={imgMedia(currentMedia!)}
            items={media.map(item => ({
              ...item.mediaContentResponse,
              id: item.id,
            }))}
            className={styles.personImg}
          />
        </div>
        <div className={styles.shortsPhotos}>
          {media?.length > 1 &&
            media?.map(content => (
              <button
                key={content.mediaContentResponse.previewLink}
                type="button"
                className={styles.photoContainer}
                onClick={() => setCurrentMedia(content)}
              >
                <img
                  key={content.mediaContentResponse.previewLink}
                  className={cn(styles.shortImg, {
                    [styles.shortImg_selected]: currentMedia?.id === content.id,
                  })}
                  src={content.mediaContentResponse.previewLink}
                  alt="img"
                />
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewListImage;
