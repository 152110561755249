import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { phoneCountryPrefixCorrection } from '../../../../1_shared/helpers/phoneCountryPrefixCorrection';
import { PhoneOrderInput } from '../../../../3_features';
import { IApplicationFormAgreement } from '../interface/IApplicationForm';

/**
 * Поле для авторизации и отображения логина пользователя на странице оформления заявки на сессию
 * */
const OrderFormLoginField = ({
  user,
  status,
  errorTimerMessage,
  handleChangePhone,
  foundUser,
  setStatus,
  setOpenCode,
  setIsLogin,
  setErrorTimerMessage,
}: {
  user: any;
  status: any;
  errorTimerMessage: any;
  handleChangePhone: any;
  foundUser: any;
  setStatus: Dispatch<SetStateAction<any>>;
  setOpenCode: Dispatch<SetStateAction<any>>;
  setIsLogin: Dispatch<SetStateAction<any>>;
  setErrorTimerMessage: Dispatch<SetStateAction<any>>;
}): ReactElement => {
  const { control } = useFormContext<IApplicationFormAgreement>();

  return (
    <Controller
      control={control}
      name="login"
      render={({ field: { value, onChange } }) => (
        <PhoneOrderInput
          user={user}
          status={status}
          value={phoneCountryPrefixCorrection(value)}
          onChange={onChange}
          errorTimerMessage={errorTimerMessage}
          setStatus={setStatus}
          setOpenCode={setOpenCode}
          setIsLogin={setIsLogin}
          setErrorTimerMessage={setErrorTimerMessage}
          handleChangePhone={handleChangePhone}
          foundUser={foundUser}
        />
      )}
    />
  );
};

export default OrderFormLoginField;
