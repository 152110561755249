import { Modal } from 'antd';
import useMessage from 'antd/es/message/useMessage';

import { Typography } from '1_shared/ui';

import { ISpecSubscription } from './interfaces/ISpecSubscription';

import styles from './SpecSubscription.module.scss';

const SpecSubscription = (props: ISpecSubscription) => {
  const [messageApi, contextHolder] = useMessage();

  const { isSubscribed = false, onCancel } = props;

  return (
    <Modal className={styles.modal} {...props}>
      <div className={styles.root}>
        <div className={styles.description}>
          {/*  <Typography type="textM" component="strong"> */}
          {/*    {`Подписка на специалиста ${specSubName} - ${!isSubscribed ? 'отменена' : 'оформлена'}!`} */}
          {/*  </Typography> */}
          {/*  <Typography type="p" component="h2"> */}
          {/*    {!isSubscribed */}
          {/*      ? `Вы больше не будете получать уведомления, когда у специалиста "${specSubName}" появится слот` */}
          {/*      : `Когда у специалиста "${specSubName}" появится слот мы вам сообщим по средствам ${userMessageType === EMessageType.PHONE ? 'смс' : 'электронного письма'}!`} */}
          {/*  </Typography> */}
          {/* </div> */}

          {/* <Button type="primary" className={styles.button} onClick={onCancel}> */}
          {/*  Закрыть */}
          {/* </Button> */}
          <Typography type="textM" component="strong">
            {`Заявка ${!isSubscribed ? 'отменена' : 'оформлена'}!`}
          </Typography>
          <Typography type="p" component="h2">
            {!isSubscribed
              ? `Вы больше не будете получать уведомления, когда у специалиста появится слот.`
              : `Когда специалист откроет слоты, мы обязательно сообщим Вам об этом.`}
          </Typography>
        </div>
      </div>
      {contextHolder}
    </Modal>
  );
};

export default SpecSubscription;
